.header {

    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    overflow: auto;
    pointer-events: none;

    @include media-breakpoint-up($menu-horizontal) {
      position: absolute;
      overflow: unset;
    }

  &__brand {
    padding: 5px;
    margin-left: 0;
    display: block;
    width: 100px;
    z-index: 1;
    margin-right: auto;

    @include media-breakpoint-up($menu-horizontal) {
      margin-top: 0;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      width: 140px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__search {
    padding-top: 23px;
    margin-left: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    z-index: 99;

    @include media-breakpoint-up($menu-horizontal) {
      margin-bottom: 21px;
      margin-left: 0;
      padding: 0;
      bottom: 0;
      width: 25px;
      margin: 0;
      margin-right: 10px;
      margin-left: 14px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      pointer-events: none;
    }
  }

  &__contact {
    display: none !important;
    min-width: 130px !important;

    @include media-breakpoint-up($menu-horizontal) {
      display: flex !important;
    }
  }

  &__menu {
    //background: map-get($background-color, 'primary');
    position: absolute;
    z-index: 99999;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    flex-direction: row;
    align-items: center;
    top: 0;
    gap: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    background-color: black;
    margin-right: 0;
    pointer-events: all;

    @include media-breakpoint-up($menu-horizontal) {
      height: 100px;
      background-color: transparent;

      &.show {
        position: fixed;
        width: 100%;
        background: map-get($background-color, 'primary');
        //height: 100px;
        animation: slideDown 0.3s ease; //0 1 forwards;
      }
    }
  }

  .hamburger {
    display: flex;
    align-items: inherit;
    flex-direction: column;
    font-size: 17px;
    font-weight: 500;
    color: white;
    margin-left: 0;
    justify-content: center;
    z-index: 99;

    @include media-breakpoint-up($menu-horizontal) {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      margin-left: 0;
      display: none;
    }

    &-box {
      pointer-events: none;
    }
  }

  .hamburger-label {
    display: none;
    margin-bottom: 8px;
    pointer-events: none;

    @include media-breakpoint-up($menu-horizontal) {
      display: block;
    }
  }
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
