.testimonial {
  //padding-bottom: $spacing-large;
  text-align: center;
//  padding: 1.8rem;
  flex: 1;
  position: relative;

  @include media-breakpoint-up(lg) {
   // min-width: 50%;
  }

  &:before {
    content: "“";
    width: 161px;
    height: 187px;
    color: #f5f5f5;
    font-family: "Helvetica Neue";
    font-size: 320px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    position: absolute;
    top: -110px;
    left: 0;
    z-index: 0;
  }

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  &__text {
    position: relative;
    z-index: 1;
  }

  &__title {
    @extend .heading-large;

    margin-bottom: $spacing-med;
    color: map-get($font-color, 'dark');
  }

  &__testimonial {
    font-size: rem(32px);
    font-weight: 700;
    margin-bottom: $spacing-med;
  }

  &__name {
    font-size: rem(24px);
    color: map-get($font-color, 'dark');
    font-weight: 700;
    display: block;
  }

  &__authority {
    font-size: rem(18px);
    color: map-get($font-color, 'highlight');
    font-weight: 700;
    display: block;
  }

  &__rating {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }


  &__rating-star {
    display: block;
    padding: 2px;

    &:before {
      content: "☆";
      color: map-get($font-color, 'highlight');
      font-size: rem(32px);
      display: block;
    }

    &.fill:before {
      content: "★";
    }
  }
}