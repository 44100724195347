$arrow-size: 45vh;

.arrow-cta {
  @include cta();
  overflow: hidden;
  font-weight: 400;

  @include media-breakpoint-up(md) {
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: $arrow-size solid transparent;
      border-bottom: $arrow-size solid transparent;
      border-right: $arrow-size solid map-get($background-color, 'secondary');;
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      top: auto;
      z-index: 0;

      margin-top: -$arrow-size;
      top: 50%;
      transform: translateY(0);
    }

  }

  &--reverse {
    background: black;
    color: white;

    &:before {
      transform: rotate(180deg) translateY(0);
    }

    .arrow-cta__image {
      object-position: right;
    }

    .container {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__button {
    margin-bottom: 5px;
    margin-right: 1rem;
  }

  &__image {
    display: none;
    object-position: left;
    @include media-breakpoint-up(lg) {
      display: block;
      height: 100%;
      object-fit: cover;
    }

    @at-root .arrow-cta--reverse & {
      left: 0;
    }
  }

  &__title {
    @extend .heading-xx-large;

    font-weight: 900;
    line-height: 1;
  }

  &__sub-title {
    margin-bottom: 2rem;
    color: map-get($font-color,  'highlight');
  }

  &__text {
    margin-bottom: 3.5rem;

    @include media-breakpoint-up(md) {
      line-height: 1.8;
    }
  }

  // Home Animations
  &.to-be-animated {

    &:before {
      right: -100%;
    }

    .arrow-cta__image {
      right: -80%;
    }

    &.arrow-cta--reverse {
      &:before {
        right: auto;
        left: -100%;
      }

      .arrow-cta__image {
        right: auto;
        left: -80%;
      }
    }
  }

  &.animated {
    transition: all 0.25s ease;

    &:before {
      transition: all 1s ease;
      right: 0;
    }

    .arrow-cta__image {
      transition: all 2s ease;
      transition-delay: 250ms;
      right: 0;
    }

    &.arrow-cta--reverse {

      .arrow-cta__image {
        left: 0;
      }

      &:before {
        left: 0;
      }
    }
  }
}
