.card-solutions-edition {
  z-index: 1;
  bottom: 0;
  left: 0;
  position: relative;
  overflow: hidden;
  margin: 0 0 rem(40px) 0;
  flex: 1;
  color: map-get($font-color, 'primary');
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;

  &:first-child {
    margin-left: -1.8rem;
  }

  @include media-breakpoint-up(md) {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  @include media-breakpoint-up(lg) {
    min-width: 28vw;
  }

  &:hover {
    text-decoration: none;
    color: map-get($font-color, 'primary');

    .card-solutions-edition__image-container {
      &:before {
        height: 100%;
      }
    }
  }

  &__inner {
    background:white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 1.8rem 1rem 1.8rem;
    transition: all 0.4s ease-in;
  }

  &__image-container {
    padding-bottom: 140%;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #be2228 100%);
      z-index: 1;
      transition: all 0.2s ease-in;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    z-index: 0;
    transform: scale(1);
  }

  &__content {
    margin-top: auto;
    position: absolute;
    z-index: 2;
    padding: $spacing-med;
    color: white;
    bottom: 0;
  }

  &__text {
    font-size: map-get($font-size, 'heading-large' );
  }

  &__index {
    color: map-get($font-color, 'secondary' );
    font-size: map-get($font-size, 'heading-xxx-large' );
    font-weight: 600;
  }
}