.solutions-magazine {
  @include cta();

  background-size: cover;
  background-color: black;

  &:before {
    content: "";
    background: map-get($background-color, 'tertiary');
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0.9;
  }

  .container {
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin-left: auto;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      max-width: 52.5%;
    }
  }

  &__image-container {
    max-width: 100%;
    position: relative;
    margin: rem(40px) auto;

    @include media-breakpoint-up(md) {
      top: auto;
      width: 43.5%;
      height: 87%;
      position: absolute;
      left: 0;
      right: auto;
    }
  }

  &__image {
    position: relative;
    left: auto;
    width: 100%;
    height: calc(100% - #{rem(120px)});
    object-fit: cover;
    object-position: center;
    max-width: 100%;
  }

  &__issue-date {
    position: absolute;
    bottom: 0;
    height: 8rem;
    background: map-get($background-color, 'primary');
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(32px);
    font-weight: 800;
  }

  &__title {
    font-size: 5.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 7rem;
    }
  }

  &__sub-title {
    font-size: 2rem;
  }

  &__text-subheading {
    margin-bottom: 1rem;
    color: map-get($font-color, 'highlight');
    font-style: italic;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    display: block;
    font-size: 16px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    a {
      flex: 1;
      display: flex;
      color: map-get($font-color, 'light');

      &:first-child {
        border-bottom: 1px solid #fff;
        padding-bottom: 15px;
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;

          &:after {
            content: "";
            min-width: 1px;
            max-width: 1px;
            background: white;
            margin: 0 2rem;
            flex: 1;
          }
        }
      }
    }
  }
}

.pagination-prev, .pagination-next {
  position: fixed;
  z-index: 0;
  width: 4rem;
  display: block;
  margin-left: 0;
  top: 0;
  height: 100vh;
  background: transparent;

  @include media-breakpoint-up(md) {
    width: 8rem;
    display: block;
    margin-left: -8rem;
  }

  &.disabled, &.disabled a {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  a {
    width: 4rem;
    height: 4rem;

    @include media-breakpoint-up(md) {
      width: 8rem;
      height: 8rem;
    }

    position: absolute;
    bottom: 0;
    background: map-get($background-color, 'dark');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background: map-get($background-color, 'primary');
    }

    &:after {
      @include arrow(white);

      position: absolute;
      width: 2rem;
      transform: rotate(180deg);
      align-items: center;
      margin-top: -7%;
    }
  }
}

.solutions-magazine__button--view-all {
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    margin-top: 0;
  }
}


.pagination-next {
  margin-right: -4rem;
  left: 100%;

  @include media-breakpoint-up(sm) {
    margin-right: -1rem;
    right: 0;
    left: auto;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 6rem;
  }

  a {
    background: map-get($background-color, 'primary');

    &:hover {
      background: map-get($background-color, 'dark');
    }

    &:after {
      transform: rotate(0);
      margin-top: 7%;
    }
  }
}

.single-solutions_magazine .entry-content {
  .container {
    //max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
  }
  .share-buttons {
     margin-left: auto;
  }
  .image-video {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .image-video .container {
    margin-left: auto;
    margin-right: auto;
    gap: 20px;

    .image-video__col {
      margin: 0;
    }
  }

  p {
    margin-bottom: 2rem;
  }

  .page-intro__text p:last-child {
    margin-bottom: 0;
  }

  .page-intro__chapter {
    color: #e02926;
    font-weight: 700;
  }
}
