.card-standard {
  z-index: 1;
  bottom: 0;
  left: 0;
  position: relative;
  overflow: hidden;
 // margin: 0 0 rem(40px) 0;
  flex: 1;
  color: map-get($font-color, 'primary');
//  width: 100%;
//  flex-basis: 100%;
  //max-width: 100%;
  //min-width: 100%;
 // flex-direction: column;
 min-width: 100%;
 max-width: 100%;

  @include media-breakpoint-up(md) {
  // width: 50%;
  //  flex-basis: 50%;
  //  max-width: 50%;
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
   // min-width: calc(50% - #{$card-gutter}*2.5);
  }

  @include media-breakpoint-up(lg) {
   // width: 33.3333%;
  //  flex-basis: 33.3333%;
  //  max-width: 33.3333%;
      // max-width: calc(33.333333333% - #{$card-gutter}/2);
      width: calc(33.333333333% - 1.3333rem);
      min-width: calc(33.333333333% - 1.3333rem);
      max-width: calc(33.333333333% - 1.3333rem);
    //  min-width: calc(33.333333333% - #{$card-gutter}*2.5);
  }

  &.full-width {
    flex-basis: 100%;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  &:hover {
    text-decoration: none;
    color: map-get($font-color, 'primary');
  }

  &__inner {
    background:white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
//    margin: 0 1.8rem 1rem 1.8rem;

    @at-root .card-standard--border & {
      border: solid 1px #e3e3e3;
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      height: 100%;

      @at-root .full-width & {
        flex-direction: row;
      }

      @at-root .highlight & {
        flex-direction: row-reverse;
        background: map-get($background-color, 'primary');
        * {
          color: white;
        }

        .button:not(:hover) {
          background: white !important;
          color: map-get($font-color, 'highlight') !important;

          &:before {
            background: white !important;
          }
        }
      }
    }
  }

  &__image-container {
    padding-bottom: 57%;
    overflow: hidden;
    position: relative;
    // margin-top: $spacing-med;

    @include media-breakpoint-up(lg) {
      @at-root .full-width & {
        min-height: 400px;
        padding-bottom: 0;
        width: 50%;
        margin-top: 0;
      }
    }
  }

  &__title + &__excerpt {
    margin-top: 2rem;
  }

  &__image {
    height: 100%;
    width: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: all 0.2s;
    transform: scale(1);
    flex: 1;
  }

  &__title {
    @extend .heading-medium;

    color: map-get($font-color, 'dark');

    @at-root .search-result & {
      color: map-get($font-color, 'highlight');
    }

    margin-bottom: 0;
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      @at-root .full-width & {
        font-size: 3.6rem;
        line-height: 1.1;
      }
    }
  }

  &__sub-title {
    @extend .heading-small;

    position: relative;
    color: map-get($font-color, 'highlight');
    margin-bottom: $spacing-med;
    display: inline-block;

    @at-root .news-and-insights & {
      margin-bottom: 1.3rem;
      display: inline-block;
      font-size: 1.2rem;
    }

    &:before {
      content: "";
      width: 50%;
      height: 2px;
      position: absolute;
      bottom: rem(-20px);
      transform: scaleX(0);
      margin-top: 3px;
      display: block;
      transform-origin: left;
      background: map-get($font-color, 'highlight');
    }
  }

  &__text {
    padding: 4rem 3rem;
    flex: 1;

    @at-root .search-result & {
      padding: 3rem 0;
      padding-bottom: 3rem;
    }

    @include media-breakpoint-up(lg) {
      @at-root .full-width & {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8.6rem;
      }
    }
    @at-root .search-result & {
      &:after {
        content: "";
        height: 1px;
        width: 100%;
        margin: 0 auto;
        top: auto;
        display: block;
        bottom: -3rem;
        position: relative;
        background-color: map-get($font-color, 'primary');
      }

      @at-root .post:last-child & {
        &:after {
          display: none;
        }
      }
    }
  }
}

body.page-template-template-listing {
  .card-standard .card-standard__inner .card-standard__title {
    font-weight: 800;
  }
}
