.media-insights {
  display: flex;
  background: map-get($background-color, 'tertiary');
  padding: $spacing-med;
  color: white;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &__title {
    font-size: map-get($font-size, 'heading-x-large' );
    font-weight: 600;
    line-height: 1;
    margin-bottom: $spacing-med;
  }

  &__col {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: $spacing-med;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  strong {
    display: block;
    margin-bottom: $spacing-small;
  }

  &__contact-link {
    margin-left: 1rem;
    padding-bottom: 1rem;
    color: map-get($font-color, 'highlight');
  }

  span {
    min-width: 21px;
    display: inline-block;
  }

  h3 {
    margin-bottom: $spacing-med;
  }

  p {
    margin-bottom: $spacing-med;
  }
}