.news-navigation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  height: rem(80px);
  margin-bottom: -2rem;
  padding: 0 $spacing-med;
  margin-top: $spacing-large;

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }

  &__label {
    color: map-get($font-color, 'highlight');
    font-weight: 500;
    margin-right: auto;
    width: 80px;
  }

  &__search {
    margin-left: auto;
    width: 80px;
    text-align: right;
  }

  &__item {
    display: block;
    flex: 1;
    text-align: center;
  }

  &__link {
    //@extend %link-underline;
    display: block;
    font-size: rem(20px);
    color: map-get($font-color, 'primary');
    font-weight: 500;
    padding: 0 rem(20px);
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      color: map-get($font-color, 'highlight');
    }
  }
}
