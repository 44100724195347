.solutions-header {

  &__brand {
    display: block;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__issue-date {
    display: flex;
    align-items: center;
    color: white;
    margin-left: 2rem;
    font-weight: 600;
  }

  &__menu {
    background: map-get($background-color, 'primary');
    position: fixed;
    z-index: 99999;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    flex-direction: row;
    padding: 1rem 2rem;
    display: flex;
  }

  .hamburger {
    display: flex;
    align-items: inherit;
    flex-direction: row;
    font-size: 17px;
    font-weight: 500;
    color: white;
    margin-left: 5rem;
    justify-content: center;
    align-items: center;

    &-box {
      pointer-events: none;
    }
  }

  &__chapter-btn {
    width: 50px;
    height: 100%;
    margin-left: auto;
    margin-right: 2rem;
    position: relative;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    cursor: pointer;

    &:after {
      @include book(white);

      position: relative;
      width: 26px;
      height: 26px;
      min-width: 26px;
      display: block;
      margin-bottom: -6px;
      margin-left: 1rem;
      transition: opacity 0.5s ease-in;
    }

    &.is-active:after {
      opacity: 0;
    }

    &-label {
      margin-left: 1rem;
      color: white;
      pointer-events: none;
    }
  }

  .hamburger-label {
    display: none;
    margin-bottom: 0;
    pointer-events: none;
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .hamburger-box {
    left: 1rem;
    opacity: 0;
  }

  .hamburger--collapse .hamburger-box {
    opacity: 1;
    transition: opacity 1.2s ease;
  }
}