.text-video {

    &__title {
        max-width: 900px;
        margin-right: auto;
        color: map-get($font-color, 'dark');
        margin-bottom: 3.125rem;
        font-size: 1.9375rem;
    }

    .image-video__col {
        @include media-breakpoint-up(lg) {
            padding-left: 15px;
        }

        .video {
            height: 100%;

            .plyr {
                @include media-breakpoint-up(lg) {
                    height: 100%;
                    transition: all 0.5s ease;

                    &--playing {
                        height: 0;
                    }
                }
            }
        }
    }
}