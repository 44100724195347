/** Search form */

select,
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  height: rem(80px);
//  border: solid 1px rgba(62, 64, 65, 0.2);
  padding-left: rem(30px);
  padding-right: rem(30px);
  color: map-get($font-color, 'primary');
  width: 100%;
}

.ginput_container_select, .filter-wrapper {
  position: relative;
  width: auto;

  &:after {
    @extend .chev;

    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -10px;
    color: map-get($font-color, 'highlight');
  }

  &:before {
    content: "";
    width: 30px;
    height: calc(100% - 6px);
    margin-top: 3px;
    position: absolute;
    right: 3px;
    top: 0;
    background: white;
    pointer-events: none;
  }
}

textarea {
  height: auto;
}

.search-form {
  @extend .form-inline;
}

.search-form label {
  @extend .form-group;

  font-weight: normal;
}

.ginput_container_fileupload {
  border: 2px dashed #9a9797;
  padding: 2rem;
}

.button.gform_button_select_files {
  background-color: map-get($background-color, tertiary) !important;
  margin-left: 1rem;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.skip-logic-form {
  margin-bottom: 120px;
}

.skip-logic-form .gform_button[type="submit"] {
  background: map-get($background-color, primary) !important;
}

.gform_hidden {
  display: none !important;
}

.gfield_visibility_hidden {
  display: none; }

#gform_3 .gfmc-column.gfmc-row-1-column {
  padding: 0 !important;
  display: none; }

#gform_3 .gfmc-column.gfmc-row-1-column.show {
    display: block; }

#gform_3 .gform_footer.top_label {
  padding-bottom: 15px; }

#gform_submit_button_3 {
  transition: none !important; }

.gform_body ul {
  list-style: none;
  padding-left: 0;

  .gfield {
    margin-bottom: rem(40px);
  }

  .gfield_label {
    font-size: rem(24px);
    font-weight: 400;
  }

  .gfield_required {
    color: map-get($font-color, 'highlight');
    margin-left: 5px;
  }

  .gf_name_has_2 {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;

    > span {
      flex: 1;
      margin: 0 5px;
    }
  }
}

.validation_error {
  padding: 29px;
  background: map-get($background-color, 'primary');
  color: white;
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.validation_message {
  font-weight: 500;
  color: map-get($font-color, 'highlight');
  font-size: map-get($font-size, 'small' );

  @at-root .solutions-newsletter__form & {
    color: white;
  }
}

#complaints-form-container {
  border: 0;
}
#complaints_form {
  padding-bottom: 3rem;

	label {
		font-size: 1.5rem;
		font-weight: 400;
	}

  .row {
    margin-top: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .col-sm-6, .col-sm-12 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  h3 {
    margin-top: 4rem;
    color: map-get($font-color, 'dark');
    font-weight: 900;
  }
}
