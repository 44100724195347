@mixin cta {
  @extend .component-padding;

  position: relative;
  background: map-get($background-color, 'light');
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: map-get($font-color, 'dark');

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }

  &--dark {
    color: map-get($font-color, 'light');
  }

  .container {
    margin-left: 0;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      max-width: 55%;
    }
  }

  &__title {
    @extend .heading-xx-large;
  }

  &__button:last-child {
    margin-top: 10px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__sub-title {
    @extend .heading-medium-small;
  }

  &__text {
    margin-bottom: $spacing-med;
  }

  &__image {
    max-height: 100%;
    width: auto;
    right: 0;
    left: auto;
    bottom: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
      position: absolute;
      max-width: 40%;
      top: 50%;
      transform: translateY(-50%);
    }
    // transform: translateX(100%);
  }
}