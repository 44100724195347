$hover-offset: rem(120px);

.carousel-component {
  @extend .component-padding;

  &--single {
    padding-top: 8rem;
    background: #f5f5f5;
  }

  @include media-breakpoint-up(md) {
    margin: 0 -#{$grid-gutter-width/4};
  }

  @include media-breakpoint-up(lg) {
    margin-left: -#{$grid-gutter-width/2};
    margin-right: -#{$grid-gutter-width/2};
  }

  background: map-get($background-color, 'light');

  @include media-breakpoint-up(md) {
    min-height: 50vh;
  }

  &.people-carousel {
    @include media-breakpoint-up(md) {
      min-height: 50vh;
    }
  }

  &__title {
    @extend .heading-larger;

    color: map-get($font-color, 'dark');
    margin-bottom: 2rem;
  }

  .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      padding-left: #{$grid-gutter-width/4};

      .carousel-component .container {
        padding-left: #{$grid-gutter-width/4};
      }
    }

    @include media-breakpoint-up(lg) {
      padding-left: #{$grid-gutter-width/2};

      .carousel-component .container {
        padding-left: #{$grid-gutter-width/2};
      }
    }
  }

  .container.card-row {
    flex-direction: row;
  }

  &__sub-title {
    @extend .heading-medium-small;

    color: map-get($font-color, 'highlight');
  }

  &__list {
    flex-direction: column;


    @include media-breakpoint-up(lg) {
      &:after {
        content: "";
        width: 8rem;
        height: 100%;
        background: black;
        opacity: 0.8;
        bottom: 0;
        right: 0;
        position: absolute;
        background: linear-gradient(270deg, map-get($background-color, 'light') 0%, map-get($background-color, 'light') 65%, rgba(0,0,0,0) 100%);
        z-index: 1;
      }
      &:before {
        content: "";
        width: 8rem;
        height: 100%;
        background: black;
        opacity: 0.8;
        bottom: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(90deg, map-get($background-color, 'light') 0%, map-get($background-color, 'light') 65%, rgba(0,0,0,0) 100%);
        z-index: 1;
      }
    }

    &.card-row {
      margin: 0 1.2rem;

      @include media-breakpoint-up(sm) {
        margin: 0;
        padding: 0;
      }
    }
  }

  .flickity-button {
    z-index: 9;
  }

  .carousel-cell {
    opacity: 0 !important;
    transition: opacity 1s ease !important;
    &.is-selected {
      opacity: 1 !important;
    }
  }
  .flickity-page-dots {
    bottom: -54px;
  }

  .flickity-page-dots .dot {
    background: #bbb;
    border: 1px solid #bbb;
    opacity: 1;

    &.is-selected {
      background: transparent;
      border: 1px solid map-get($font-color, 'highlight');
    }
  }


  .card-standard {
    position: relative;
    flex: 1;
    margin: 0 20px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    // height: 100%;
    min-height: 100%;
    background: white;

    width: 70%;
    max-width: 400px;
    min-width: 360px;
    display: flex;
    align-items: stretch;

    &__inner {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 33%;
      max-width: 100%;
      min-width: unset;
      //max-width: calc(33% - 20px*2 - 16rem/2);
    }

    @media (min-width: 1500px) {
      width: 24%;
      //max-width: calc(27% - 20px*2 - 16rem/2);
    }

    @media (min-width: 2000px) {
      width: 27%;
      //max-width: calc(20% - 20px*2 - 16rem/2);
    }

    &__title-wrapper {
      padding-left: 3rem;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }

    &__excerpt {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 16px;

      @include media-breakpoint-up(md) {
        margin-bottom: 8rem;
      }
    }

    &__inner {
      margin: 0;
      height: 100%;
    }

    &__image-container {
      padding-bottom: 100%;
    }

    &__sub-title {
      margin-bottom: 1rem;
    }

    &__text {
      line-height: 1.6;

      @media (min-width: 1500px) {
        padding: 20px 15px;
      }
    }

    .button {
      @include media-breakpoint-up(md) {
        position: absolute !important;
        bottom: 1.4rem;
      }
    }
  }
}


.flickity-slider {

  margin-left: 0;

  @include media-breakpoint-up(md) {
    margin-left: 4rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 8.6rem;
  }
}
