.profile {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  &__image {
    margin-top: 2rem;

    @include media-breakpoint-up(sm) {
      max-width: 30%;
      margin-left: 4rem;
      flex: 1;
      margin-top: 0;
    }

    img {
      max-width: 100%;
      margin-bottom: rem(70px);
    }
  }

  &__bio {
    flex: 1;
  }

  & &__leader-title {
    font-size: rem(32px);
    color: map-get($font-color, 'highlight');
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 4rem;
    border-bottom: 1px solid #bbbbbb;
    padding-bottom: 2rem;

    a {
      color: map-get($font-color, 'highlight');
    }


    &:first-child {
      margin-top: 0;
    }

  }

  & &__leader-sub-title {
    font-size: rem(20px);
    color: #bbbbbb;
    font-weight: 700;
  }

  &__leader-link {
    font-size: rem(24px);
    color: map-get($font-color, 'dark');
    font-weight: 700;
    display: block;
    margin-bottom: 1rem;

    &:hover, &.active {
      color: map-get($font-color, 'highlight');
    }
  }
}