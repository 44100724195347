.card-solutions-magazine {
  z-index: 1;
  bottom: 0;
  left: 0;
  position: relative;
  overflow: hidden;
  margin: 0 0 rem(40px) 0;
  flex: 1;
  color: map-get($font-color, 'primary');
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33.3333%;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  &:hover {
    text-decoration: none;
    color: map-get($font-color, 'primary');
  }

  &__inner {
    background:white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   // margin: 0 1.8rem 1rem 1.8rem;
  }

  &__image-container {
    padding-bottom: 140%;
    overflow: hidden;
    position: relative;
  }

  &__image {
    height: 100%;
    width: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: all 0.2s;
    transform: scale(1);
  }
}
