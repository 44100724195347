$hover-offset: rem(150px);

.news-and-insights {
  @extend .component-padding;

  background: map-get($background-color, 'tertiary');
  overflow: hidden;

  &--light {
    background: map-get($background-color, 'body-main');;
  }

  @include media-breakpoint-up(md) {
    min-height: 100vh;
  }

  &__title {
    @extend .heading-xx-large;

    @at-root .home & {
      //font-size: 5.5rem;
    }

    @at-root .news-and-insights--light & {
      color: map-get($font-color, 'dark');
    }

    color: map-get($font-color, 'light');
  }

  .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__sub-title {
    @extend .heading-medium-small;

    color: map-get($font-color, 'highlight');
  }

  &__list {
    &:after {
      content: "";
      width: 0;
      @include media-breakpoint-up(md) {
        width: calc(4.5% + 1rem);
      }
      @include media-breakpoint-up(xl) {
        width: calc(7.5% + 1rem);
      }
      height: 100%;
      background: black;
      opacity: 0.8;
      bottom: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(270deg, map-get($background-color, 'tertiary') 0%, map-get($background-color, 'tertiary') 65%, rgba(0,0,0,0) 100%);
      z-index: 1;
    }
    &:before {
      content: "";
      width: 0;
      @include media-breakpoint-up(md) {
        width: calc(4.5% + 1rem);
      }
      @include media-breakpoint-up(xl) {
        width: calc(7.5% + 1rem);
      }
      height: 100%;
      background: black;
      opacity: 0.8;
      bottom: 0;
      left: 0;
      position: absolute;
      background: linear-gradient(90deg, map-get($background-color, 'tertiary') 0%, map-get($background-color, 'tertiary') 65%, rgba(0,0,0,0) 100%);
      z-index: 1;
    }

    @at-root .news-and-insights--light & {
      &:before {
        background: linear-gradient(90deg, map-get($background-color, 'body-main') 0%, map-get($background-color, 'body-main') 65%, rgba(0,0,0,0) 100%);
      }
      &:after {
        background: linear-gradient(270deg, map-get($background-color, 'body-main') 0%, map-get($background-color, 'body-main') 65%, rgba(0,0,0,0) 100%);
      }
    }
  }

  &__list-title {
    @extend .heading-medium-small;
    margin-bottom: rem(20px);
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  &__list-text {
    color: map-get($font-color, 'default');
    font-size: 15px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &__list-image {
    min-height: calc(50% + #{$hover-offset});
    left: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: all 0.2s;
  }

  &__item {
    position: relative;
    flex: 1;
    margin: 0 20px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    width: 100%;
    max-width: 400px;

    @include media-breakpoint-up(sm) {
      width: 70%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 26%;
      max-width: 100%;
    }
  }

  &__card {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-end;
    z-index: 1;
    bottom: 0;
    left: 0;
    position: relative;
    overflow: hidden;

    @extend %image-hover-red-mask;

    &:hover {
      &:before {
        opacity: 0.8;
      }

      .news-and-insights__content {
        transform: translateY(0);
      }

      .news-and-insights__image-container:before {
        opacity: 0.8;
      }

      .news-and-insights__list-image {
        transform: scale(1.1);
      }
    }
  }

  &__read-more {
    margin-top: $spacing-med;
  }

  &__content {
    background: map-get($background-color, 'body-main');
    color: map-get($font-color, 'dark');
    position: relative;
    z-index: 1;
    padding: rem(40px);
    position: absolute;
    transition: all 0.4s ease;
    width: 100%;

    @at-root .news-and-insights--light & {
      background: map-get($background-color, 'light');
    }

    @include media-breakpoint-up(md) {
      transform: translateY( $hover-offset );
    }

    &-inner {
      // min-height: calc(50% + #{$hover-offset});
      min-height: 215px;
    }
  }

  &__image-container {
    width: 100%;
    position: relative;
    padding-bottom: 170%;

    @include media-breakpoint-up(md) {
      padding-bottom: 160%;
      // @include %image-hover-red-mask;
    }

  }

  &__nav {
    list-style: none;
    display: none;
    padding-left: 0;
    margin-bottom: $spacing-med;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    li {
      position: relative;
      margin: 0 rem(10px);
    }

    &-link {
      @extend %link-underline;

      color: map-get($font-color, 'light');
      font-weight: 500;
      font-size: rem(20px);

      @at-root .news-and-insights--light & {
        color: map-get($font-color, 'dark');

        &:hover {
          color: map-get($font-color, 'dark');
        }
      }

      &:hover {
        color: map-get($font-color, 'light');
      }
    }
  }

  .flickity-button {
    z-index: 9;
  }

  .carousel-cell {
    opacity: 0 !important;
    transition: opacity 1s ease !important;
    &.is-selected {
      opacity: 1 !important;
    }
  }
  .flickity-page-dots {
    position: relative;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      bottom: -54px;
      left: 0;
      margin-top: 0;
      position: absolute;
    }
  }

  .flickity-page-dots .dot {
    background: #bbb;
    border: 1px solid #bbb;
    opacity: 1;

    &.is-selected {
      background: transparent;
      border: 1px solid map-get($font-color, 'highlight');
    }
  }
}
