$gutter: 14rem;


.container {
  padding-left: $grid-gutter-width/8;
  padding-right: $grid-gutter-width/8;

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width/4;
    padding-right: $grid-gutter-width/4;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@mixin offsetContainerPadding() {
 // margin-left: -#{$grid-gutter-width/4} !important;
 // margin-right: -#{$grid-gutter-width/4} !important;

  @include media-breakpoint-up(md) {
    margin-left: -#{$grid-gutter-width/4} !important;
    margin-right: -#{$grid-gutter-width/4} !important;
  }

  @include media-breakpoint-up(xl) {
    margin-left: -#{$grid-gutter-width/2} !important;
    margin-right: -#{$grid-gutter-width/2} !important;
  }
}



/* Content Thin Layouts. */
.content-thin {
  .main {
    margin: 0;
    background: white;
    padding-top: 0.1px; // stop margin collapse
    padding-bottom: 0.1px; // stop margin collapse
  }

  &.page-template-template-listing .main {
    background: transparent;

    .card-layout .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include media-breakpoint-up(md) {
    .section-bottom .container {
      padding-left: 0;
      padding-right: 0;
    }
  }



  .main,
  .hero .container,
  .section-bottom,
  .hero--full-height .container {
    @include media-breakpoint-up(md) {
      margin: 0 $grid-gutter-width/4;
      margin-bottom: $spacing-large;
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $grid-gutter-width/2;
      margin-right: $grid-gutter-width/2;
    }
  }

  .card-layout--grey .container {
    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


.text-image + .text-columns {
  margin-top: -1rem;
}
