.solutions-newsletter {


  background: map-get($background-color, 'tertiary');
  background-size: cover;
  position: relative;
  margin-top: 4rem;

  @include media-breakpoint-up(md) {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--new-post-subscription {
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
    display: flex;

    &:last-child {
      margin-bottom: 0 !important;
    }

    @include media-breakpoint-up(md) {
      min-height: auto;
    }
  }

  &__col {

    @include media-breakpoint-up(md) {
      max-width: 50%;
    }

    flex: 1;
  }

  &::before {
    content: "";
    background-image: linear-gradient(to top, rgba(17, 17, 17, 0) 0%, #111111 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__title {
    @extend .heading-xxx-large;

    color: map-get($font-color, 'light');
    margin-bottom: 2rem;
  }

  &__text {
    color: map-get($font-color, 'light');

    font-size: rem(24px);
    line-height: 2;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: rgba(map-get($background-color, 'primary'), 0.8);
    gap: 3rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @at-root .solutions-newsletter--new-post-subscription & {
      background-color: rgba(map-get($background-color, 'tertiary'), 0.8);
      width: 100%;

      @include media-breakpoint-up(md) {
        gap: 6rem;
        padding: $spacing-large;
      }
    }

    padding: 6rem;

    position: relative;
    z-index: 5;

    .gform_confirmation_message {
      color: white;
    }
  }

  &__form {

    .gform_fields li {
      margin-bottom: 10px;
    }

    input {
      display: block !important;
      margin-bottom: 0;

      &[type="submit"] {
        background: black !important;

        @at-root .solutions-newsletter--new-post-subscription & {
          background: white !important;
          color: black !important;
        }
      }
    }

    label {
      display: none;
    }

  }

  &__button {
    background-color: map-get($background-color, 'secondary');
    color: map-get($font-color, 'dark') !important;

    @at-root .solutions-newsletter--new-post-subscription & {
      color: map-get($font-color, 'dark') !important;
    }

    &:hover {
      color: map-get($font-color, 'light') !important;

      @at-root .solutions-newsletter--new-post-subscription & {
        color: map-get($font-color, 'dark') !important;
      }
    }
  }


  &__sub-title {
    @extend .heading-medium-small;

    color: map-get($font-color, 'light');
    margin-bottom: 1rem;
    display: block;
  }
}
