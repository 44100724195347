:root {
  --plyr-range-fill-background: map-get($background-color, 'primary')
  --plyr-color-main: map-get($background-color, 'primary')
  --plyr-color-main, map-get($background-color, 'primary')
}

.image-video {
  @extend .component-padding;

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: black;
  }

  &__col {
    height: auto;
    margin: 1%;
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  .container {
    display: flex;
    margin-left: -1%;
    margin-right: -1%;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .image-video__col.image {
    padding-bottom: 50%;
  }

  &__caption {
    position: absolute;
    bottom: 0;
    color: black;
    top: auto;
    left: 0;
    width: 100%;
    display: block;
    z-index: 2;
    background: rgba(255,255,255,0.8);
    padding: 5px 10px;
  }
}

.video {
  &__thumbnail {
    background: black;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: transform 0.4s ease;
    left: 0;
    padding-bottom: 56.25%;
    position: absolute;
  }

  &--has-lightbox .video__thumbnail:hover {
    transform: scale(1.1);
  }
}

.image__full img {
  object-fit: contain;
  background: rgba(0,0,0,0.95);
  width: 100%;
  height: 100%;
}

.plyr {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: 50% 50%;

  .plyr__video-wrapper {
    opacity: 0;
    transition: 0.3s ease;
  }

  &.plyr--playing {
    .plyr__video-wrapper {
      opacity: 1;
    }
  }
}

.image__full {
  width: 100vw;
  height: 100%;
  left: 0;
  position: fixed;
  display: block;
  top: 0;
  z-index: 99999;
  transform: scale(0.6);
  transition: all 0.4s ease-in;
  opacity: 0;
  pointer-events: none;

  &--play {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
  }

  &--close {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    background-color: map-get($background-color, 'primary');
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease;
    opacity: 0.8;
    color: white;

    &:hover {
      opacity: 1;

      &:before {
        font-weight: 400;
      }
    }

    &:before {
      content: "\00d7";
      font-size: 60px;
      line-height: 60px;
      font-weight: 200;
      transition: all 0.4s ease;
    }
  }

  // [data-plyr="fullscreen"],
  // [data-plyr="captions"],
  // [data-plyr="settings"] {
  //   display: none;
  // }
}

.plyr__control {
  &.plyr__control--overlaid {
    width: rem(70px);
    height: rem(70px);
    display: flex !important;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.4s ease;
    background: map-get($background-color, primary);

    &:hover {
      background: map-get($background-color, primary);
    }

    &-with-arrow {
      font-size: 26px;
      padding-inline-start: 22px;
    }
  }
}

body.page-intermodal-data {
  .video {
    &__thumbnail {
      padding-bottom: 100%;
    }
  }

  .image-video__col {
    padding-bottom: 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 50%;
    }
  }
}
