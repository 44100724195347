/** Import Bootstrap functions */
$html-font-size: 16px;
$menu-horizontal: 'lg';

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

$font-color: (
  primary: #606060,
  secondary: #fff200,
  highlight: #BE2228,
  dark: #111,
  light: #f1f1f1,
  lightgrey: #bbbbbb,
);

$font-family-default: "Helvetica Neue", "arial_mt", sans-serif;

$font-size: (
  default: rem(26px),
  small: rem(16px),
  heading-small: max(1.56rem, 16px),
  heading-large: max(2.5rem, 17px),
  heading-larger: max(3rem, 18px),
  heading-medium: max(2.2rem, 16px),
  heading-medium-small: max(1.75rem, 16px),
  heading-x-large: max(4rem, 18px),
  heading-xx-large: 5.5rem,
  heading-xxx-large: rem(100px),
);

$background-color: (
  primary: #BE2228,
  secondary: #fff200,
  tertiary: #111,
  body-main: #f5f5f5,
  highlight: #bbbbbb,
  light: #F5F5F5,
  dark: #111,
  footer: #F5F5F5,
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

/** Spacing **/
$spacing-small: rem(34px);
$spacing-med: rem(50px);
$spacing-large: 9rem;

$menu-size: rem(160px);
$font-size-mobile-large: 18px;

$card-gutter: 20px;

.heading {
  color: map-get($font-color, 'dark' );
  // margin-top: $spacing-large;
  // margin-bottom: calc(#{rem(40px)} - #{$spacing-large}); // will be a negative margin

  &--light {
    color: white;
  }
}

.heading-xxx-large {
  font-size: map-get($font-size, 'heading-xxx-large');
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-xx-large {
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.03em;
  font-size: map-get($font-size, 'heading-x-large');
  margin-bottom: $spacing-med;

  @include media-breakpoint-up(lg) {
    font-size: map-get($font-size, 'heading-xx-large');
  }
}

.heading-x-large {
  font-size: map-get($font-size, 'heading-x-large');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-larger {
  font-size: map-get($font-size, 'heading-larger');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-large {
  font-size: map-get($font-size, 'heading-large');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-medium {
  font-size: map-get($font-size, 'heading-medium');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-medium-small {
  font-size: map-get($font-size, 'heading-medium-small');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

.heading-small {
  font-size: map-get($font-size, 'heading-small');
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.03em;
}

@mixin wysiwyg-content {
  h1, h2 {
    font-size: 2.8rem;
    letter-spacing: -0.03em;
    font-weight: 600;
    line-height: 1.2;
    color: map-get($font-color,  'dark');
    margin-bottom: 1rem;
  }

  h3 {
    @extend .heading-medium;

    line-height: 1.2;
    color: map-get($font-color,  'highlight');
    margin-bottom: 2rem;
  }

  h4 {
    @extend .heading-medium-small;

    line-height: 1.2;
    color: map-get($font-color,  'dark');
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 0;
    list-style-position: inside;

    li {
      margin-bottom: 2.6rem;
      margin-bottom: 2rem;
      line-height: 1.6;

      &::marker {
        font-size: 10px;
        color: map-get($font-color, 'highlight');
      }
    }
  }
}

.small-text {
    font-size: 1.4rem;
}

.wysiwyg-content {
  @include wysiwyg-content;
}

.component-has-color {
  padding-top: 9rem; //$spacing-large;
  padding-bottom: 9rem; //$spacing-large;
  margin-bottom: 0;
  margin-top: 0;
}

%image-hover-red-mask {
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 1;
    background: #8c0005;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
}

%link-underline {
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: rem(-10px);
    transform: scaleX(0);
    margin-top: 3px;
    display: block;
    background: map-get($font-color, 'highlight');
    left: 0;
  }

  &:hover {
    text-decoration: none;
    color: inherit;

    &:before {
      transform: scaleX(1);
      transition: transform 0.2s ease;
    }
  }
}
