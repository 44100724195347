.text-columns {
  &__container {
    display: flex;
    gap: 2rem;
    flex-direction: column;

    @include media-breakpoint-up($menu-horizontal) {
      flex-direction: row;
    }
  }

  &__column {
    flex: 1;
  }

  &--3 {
    @include media-breakpoint-up($menu-horizontal) {
      font-size: 1.4rem;
    }
  }
}
