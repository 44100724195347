.menu-active .solutions-menu {
  transform: translateX(0);
  transition: transform 0.4s ease-in;
  &, & * {
    pointer-events: all;
  }
}

.solutions-menu {
  width: 500px;
  height: 100%;
  position: fixed;
  left: auto;
  right: 0;
  z-index: 10;
  font-size: map-get($font-size, 'heading-large');
  font-weight: 600;
  position: fixed;
  padding-bottom: 0;
  background-color: map-get($background-color, 'body-main');
  background-repeat: repeat;
  transform: translateX(100%);
  box-shadow: 5px 10px 47px #949494;

  &__follow {
    position: absolute;
    bottom: 20px;
    left: 40px;
    z-index: 1;
    display: flex;
    flex-direction: column;

    &-heading {
      font-size: map-get($font-size, 'heading-small');
      font-weight: 500;
    }
  }

  &__social-list {
    display: flex;
    flex-direction: row;
    padding-left: 0;

    a svg {
      color: map-get($font-color, 'highlight');
      margin: 1rem;
    }

    & li:first-child a svg {
      margin-left: 0;
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      line-height: 1;
      margin-bottom: 0.8em;

      a {
        color: map-get($font-color, 'dark');
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  &__list {
    display: block;
    overflow: scroll;
    z-index: 2;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    padding-top: rem(200px);

    a {
      &:hover {
        color: map-get($font-color, 'highlight');
      }
    }
  }

  &__link {
    // @extend %link-underline;

    color: white;
    font-size: map-get($font-size, 'heading-x-large' );
    display: flex;
    align-items: center;

    &--small {
      font-size: map-get($font-size, 'heading-medium' );

      &:first-child {
        margin-top: 2rem;
        display: block;
      }
    }

    &-more {
      color: map-get($font-color, 'highlight' );
      font-size: map-get($font-size, 'heading-x-large' );
      // transition: transform 0.4s ease-out;
      margin-left: 20px;
      font-family: arial;
      display: block;
      line-height: 1;
    }

    &:hover {
      color: white;
    }
  }
}

.chapters-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 80px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
  z-index: 50;

  .card-layout {
    margin-top: 0;
    padding-top: 2rem;
  }

  @at-root .chapters-menu-active & {
    opacity: 1;
    z-index: 9;
    pointer-events: all;
  }

  .card-solutions-edition__image-container {
    padding-bottom: 76vh;
  }
}