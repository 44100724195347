.page-intro {
  @extend .component-padding;
  @extend .copy-styles;

  margin-top: 6.5rem;

  background: white;

  &.title-text {
    padding-top: 0;
    padding-bottom: 0;
  }

  .container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__col {
    &--text {
      // max-width: 1000px;
      flex: 1;
      min-width: 100%;

      @include media-breakpoint-up(lg) {
        min-width: 70%;
      }
    }
    &--menu {
      flex: 0.6;
      padding-left: 2rem;
      margin-left: 3rem;
    }

    flex-direction: column;

    &-heading {
      @extend .list-title;
    }

  }

  &__title {
    font-size: 4.5rem;
    margin-bottom: rem(60px);
    color: map-get($font-color, 'dark');
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.03em;
  }

  &__text {
    ul li {
      margin-bottom: 0.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  & &__menu {
    padding-left: 0;
    list-style: none;
    margin-left: auto;
    min-width: 230px;
//    max-width: 35%;
    width: 100%;
    flex-direction: column;
    display: none;
    margin-top: 0;


    @include media-breakpoint-up(lg) {
      display: flex;
    }

    li {
      margin-bottom: 0;
    }

    li:before {
      display: none !important;
    }
  }

  &__chapter {
    font-size: rem(32px);
    color: map-get($font-color, 'highlight');
    font-weight: 500;
    display: block;
    margin-bottom: $spacing-small;
  }

  &__menu-links.active {
    color: map-get($font-color, 'highlight') !important;
  }

  .has-2-levels {
    .page-intro__menu-links {
      &.level-1 {
        @extend .list-title;
      }
      &.level-2 {
        @extend .list-item;
      }
    }
  }

  .has-3-levels {
    .page-intro__menu-links {
      &.level-1 {
        @extend .list-small-title;

        display: block;
      }
      &.level-2 {
        @extend .list-title;
      }
      &.level-3 {
        @extend .list-item;
      }
    }
  }
}


.list-small-title {
  font-size: rem(20px);
  color: #bbbbbb;
  font-weight: 600;
  padding: 0;
  padding-bottom: rem(10px);
}

.list-title {
  font-size: map-get($font-size, 'heading-large');
  color: map-get($font-color, 'highlight');
  padding: 0;
  margin-bottom: rem(12px);
  font-weight: 600;
  position: relative;
  display: block;

  padding-bottom: 2.4rem;
  margin-bottom: 1.6rem;

  letter-spacing: -0.6px;
  font-size: 2.2rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #e3e3e3;
  }
}

.list-item {
  color: map-get($font-color, 'dark');
  font-weight: 600;
  padding: 0.4rem 0;
  display: block;
  letter-spacing: -0.6px;
}
