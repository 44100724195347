.footer-main {
  background-color: map-get($background-color, 'footer');

  &__seperator {
    display: block;
    width: 100%;
    margin-bottom: rem(30px);
    margin-top: 0;
    // border-top: 1px solid #BBBBBB;
    opacity: 0.4;
  }

  &__list {
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      padding: rem(3px) 0;
    }
  }

  &__cols {
    gap: 3rem;
  }

  &__col--small {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      text-align: right;
      flex-grow: 0;
      max-width: 200px;
      flex: 0;
      margin-top: 0;
    }
  }

  &__col-title {
    font-size: 17px;
    font-weight: 600;
    color: map-get($font-color, 'highlight');
    margin-bottom: 1rem;
  }

  &__row {
    padding: $spacing-med 0;
    flex-direction: column;
    text-align: center;
    display: flex;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      text-align: left;
    }
  }

  &__col {
    margin-bottom: rem(30px);
    flex: 1;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      flex-direction: column;
    }
  }

  a:not(.button) {
    color: map-get($font-color, 'dark');
    text-decoration: none;
    font-weight: 600;

    &[href^="tel:"] {
      color: map-get($font-color, 'highlight');
      text-decoration: underline;

      &:hover {
        color: map-get($font-color, 'dark');
      }
    }
  }

  &__brand {
    font-size: map-get($font-size, 'small');
    line-height: 1.8;
    flex: 1.2;

    @include media-breakpoint-up(md) {
      padding-right: rem(60px);
    }

    p {
      max-width: 380px;
    }

    img {
      margin-bottom: rem(40px);
      max-width: 130px;
      margin-top: 0rem;
      margin-right: 1rem;
    }
  }

  &__social-list {
    padding: 0;
    list-style: none;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    font-size: 42px;
    justify-content: center;

    @include media-breakpoint-up(md) {
      font-size: 30px;
      justify-content: flex-end;
    }

    .svg-inline--fa {
      max-width: 44px !important;
      max-height: 44px !important;
    }

    li {
      margin: 0 10px;
    }

    a {
      color: white;
    }

    a img {
      opacity: 0.3;
    }

    a:hover {
      img {
        transition: all 0.2s ease;
        opacity: 1;
      }
    }
  }
}

.footer-cta {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 58%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-position-x: 60%;

  @include media-breakpoint-up(md) {
    min-height: 800px;
  }


  &--text-vertical {
    padding-bottom: $spacing-large;
    justify-content: center;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.4;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
    }
  }

  &__button {
    z-index: 1;
    position: relative;
  }

  &--top {
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
    padding-bottom: $spacing-med;

    &:after {
      content: "";
      background: inherit;
      width: 100%;
      height: 120px;
      position: absolute;
      bottom: 0;
      height: 120px;
      position: absolute;
      left: 0;
      z-index: 0;
      bottom: 0;
      transform: translateY(100%);
    }

    .footer-cta__heading {
      margin-bottom: 0;
    }
  }

  &__heading {
    //font-size: map-get($font-size, 'heading-xx-large') !important;
    color: white !important;
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
    padding-top: $spacing-large;
    line-height: 1 !important;
    font-weight: 600 !important;
    margin-bottom: $spacing-med;
    position: relative;
    z-index: 1;

    font-size: 5.5rem !important;
    max-width: 800px;
    letter-spacing: -1px;
    max-width: 800px;
  }
}

.footer-bottom {
  font-size: 13px;
  position: relative;
  z-index: 4;

  display: flex;
  gap: 10rem;
  color: lighten(map-get($font-color, 'primary'), 30);

  &--solutions {
    background: map-get($background-color, 'tertiary');
    padding-top: $spacing-med;
    text-align: center;
    text-align: center;
  }

  p {
    text-align: center;
    color: lighten(map-get($font-color, 'primary'), 30);

    @include media-breakpoint-up(md) {
      max-width: 45%;
      text-align: left;
    }
  }

  &__list {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    text-decoration: underline;
    color: lighten(map-get($font-color, 'primary'), 30);
  }

  padding-bottom: $spacing-med;
}

select {
  //font-size: 24px;
  //height: 80px;
}

.menu__follow {
  position: absolute;
  bottom: 3rem;
  left: 3rem;

  @include media-breakpoint-up(md) {
    bottom: 4rem;
    right: 3rem;
    left: auto;
  }
}
