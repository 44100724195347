.hero {
  min-height: 84vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: map-get($font-color, 'light');
  position: relative;
  overflow: hidden;
  background: black;
  padding: $spacing-small 0;
  padding-top: 130px;

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    padding-top: 200px;
  }

  &--solutions-single {
    .container {
      margin-top: auto;
      margin-bottom: 10vh;
    }
  }

  &--solutions {
    &:before {
      display: none;
    }
  }

  &--half-height {
    min-height: 55vh;
    margin-bottom: $spacing-large;
    justify-content: flex-end;
    align-items: center;

    .hero__content {
      margin: 0 auto;
    }

    @at-root .search-results & {
      .hero__content {
        //margin: 0;
      }
    }
  }


  &__solutions-magazine.solutions-magazine__image-container {

    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    z-index: 50;
    height: 380px;
    width: 300px;
    right: 0;
    left: auto;
    transform: translateX(130px);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateX(0);
    }

    .solutions-magazine__issue-date {
      height: auto;
      padding: 2rem;
      flex-direction: column;
      align-items: flex-start;
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }

      h3 {
        font-size: 1.5rem;
      }
    }
  }


  @at-root .home & {

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      padding-bottom: 13.125rem;

      .hero__heading {
        font-size: 8rem;
      }

      .hero__content {
        margin-top: auto;
        max-width: 67%;

        p {
          margin-top: 2.125rem;
          margin-bottom: 3.5rem;
        }
      }
    }
  }

  &--full-height {
    margin-bottom: -33vh;
    z-index: -1;
    justify-content: flex-end;
    // margin-bottom: $spacing-large;

    @include media-breakpoint-up(md) {
      padding-top: $spacing-large;
    }

    .hero__heading {
      margin-bottom: 0;
      font-size: 6.3rem;
     // font-size: 7.5rem;
    }

    .hero__content {
      margin-top: auto;
      max-width: 67%;

      p {
        margin-top: 2.125rem;
        margin-bottom: 3.5rem;
      }
    }

    .container {
      margin-bottom: 33vh !important;
      width: auto;
    }
  }

  &:before {
    content: "";
    background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
    width: 100%;
    height: 160px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }

  &:after {
    content: "";
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }

  .button:first-child {
    @extend .button--white;
  }

  .button:last-child {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-left: 1rem;
      margin-top: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @at-root .hero--solutions & {
      flex-direction: column;
    }
  }

  &__post-meta {
    @extend .heading-small;

    color: map-get($font-color, 'highlight' );
    margin-top: rem(34px);
    display: block;
  }

  &__solutions-magazine__issue-content {
    margin-top: $spacing-med;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 0;
    }

    &-row {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;
      position: relative;

      &:hover {
        text-decoration: none;
        color: white;
      }

      @include media-breakpoint-up(md) {
        &:after {
          content: "";
          width: 1px;
          background-color: rgba(map-get($font-color, 'light' ), 0.7);
          height: 100%;
          display: block;
          position: absolute;
          right: -2rem;
          top: auto;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }
      }
    }

    &-heading {
      color: map-get($font-color, 'light' );
      font-weight: 600;
      display: block;
    }

    &-text {
      color: map-get($font-color, 'light' );
    }
  }

  &__content {
    padding-top: 3rem;


    @at-root .home & {
      max-width: 831px;
    }

    z-index: 1;
    position: relative;

    p {
      @include media-breakpoint-up(md) {
        //max-width: 500px;
        max-width: 715px;
        font-size: 2rem;
        line-height: 1;
      }

      margin-top: rem(50px);
      margin-bottom: 3.5rem;
    }
  }

  &__slides {
    z-index: 0;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: blinear-gradient(180deg,rgba(17,17,17,0),#111)lack;
      opacity: 1;
      position: absolute;
      z-index: 5;
    }

    .plyr, .plyr__video-wrapper {
      @include media-breakpoint-down(1200px) {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }

    iframe {
      position: absolute;
      width: 210vw;
      height: 100%;
      margin-left: -52vw;
      margin-top: -30vw;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 140vw;
        height: 100%;
        margin-left: -20vw;
      }
    }
  }

  &__back {
    margin-top: 1rem;
    margin-bottom: 3rem;

    a {
      text-decoration: underline;
      color: white;
    }
  }

  &__heading {
    font-size: 5.5rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
    letter-spacing: -0.15rem;

    &__issue-date {
      @extend .heading-large;

      color: white;
      display: block;
      margin-bottom: 1rem;
    }
  }

  &__pagination {
    position: absolute;
    right: rem(34px);
    top: 50%;
    transform: translateY(-50%);

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    li {
      position: relative;
      padding-right: rem(25px);
      box-sizing: border-box;
      margin-bottom: rem(10px);
      max-width: rem(130px);
      height: rem(80px);
      line-height: 1;
      font-size: rem(24px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      cursor: pointer;

      &:hover:after, &.active:after {
        background: map-get($font-color, 'highlight');
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: rem(3px);
        height: 100%;
        background: map-get($font-color, 'light');
      }
    }
  }

  .modal-video-trigger {
    margin-top: 60px;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 50px;
      bottom: 50px;
      margin-top: unset;
    }

    .trigger-thumbnail {
      width: 100%;
      height: 180px;
      margin-top: 40px;
      position: relative;
      background-size: cover;
      background-position: center center;

      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        right: 0;
        left: auto;
        width: 300px;
        height: 180px;
      }
    }
  }

  .hero-video-modal {
    transition: all 0.5s ease;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transform: translateY(20px);

    &.open {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
      z-index: 5;

      .video .inner .plyr {
        pointer-events: all;
      }
    }

    .filter {
      transition: all 0.25s ease;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: black;
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }

    .close-modal {
      transition: all 0.25s ease;
      position: absolute;
      top: 100px;
      right: 30px;
      color: white;
      font-size: 5rem;
      z-index: 2;
      transform: rotate(-45deg);
      background: unset;
      border-radius: unset;
      box-shadow: unset;
      padding: 0;
      margin: 0;
      width: 50px;
      height: 50px;
      line-height: 1;
      display: flex;
      justify-content: center;
      border: unset;
      font-weight: 300;

      @include media-breakpoint-up(lg) {
        top: 30px;
      }

      &:hover {
        transform: rotate(45deg);
        cursor: pointer;
      }
    }

    .video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      pointer-events: none;

      .inner {
        margin: auto;
        max-width: 700px;
        width: 90%;
        pointer-events: none;
      }
    }
  }
}

[data-slideshow] {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.6;
  }

  img.next {
    opacity: 1;
    z-index: 1;
  }

  img.prev {
    opacity: 1;
    z-index: 2;
  }

  img.fade-out {
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    visibility: hidden;
  }
}
