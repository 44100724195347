.values-list {
  .value-container {
    display: flex;
    margin-bottom: 50px;

    img {
      width: 33%;
      max-width: 240px;
      height: auto;
      align-self: baseline;
    }

    .text-column {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      h3 {
        margin-bottom: 15px;
      }
    }
  }
}
