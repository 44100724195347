.post-footer {
  margin-bottom: $spacing-med;

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    // border-bottom: 1px solid map-get($background-color, 'highlight');
    padding-bottom: $spacing-med;


    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .share-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: 700;
      color: map-get($font-color, 'dark');
      display: block;
      margin-right: 30px;
    }
  }

  .footer-main__social-list {
    justify-self: flex-end;
    margin-bottom: 0;
    flex: 1;

    svg {
      color: map-get($background-color, 'highlight');
      transition: color 0.2s ease;

      &:hover {
        color: map-get($font-color, 'primary');
      }
    }
  }
}

.post-tags {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  list-style: none;
  flex: 1;
  margin-bottom: 0;

  li {
    background-color: map-get($background-color, 'highlight');
    color: map-get($font-color, 'dark');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: rem(40px);
    font-size: rem(16px);
    padding: rem(15px);
    font-weight: 700;
    margin: 4px;

    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      margin-left: 0;
    }

    a {
      color: map-get($font-color, 'dark');
    }
  }
}

[data-ajax-load-more] {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}