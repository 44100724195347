.capabilities {
  @extend .component-padding;
  background: map-get($background-color, 'tertiary');

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }

  &__title {
    @extend .heading-xx-large;

    color: map-get($font-color, 'light');
    max-width: 500px;

    @include media-breakpoint-up(lg) {
      max-width: 700px;
    }
  }

  &__sub-title {
    @extend .heading-medium-small;

    color: map-get($font-color, 'highlight');
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: normal;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    * {
      z-index: 1;
      position: relative;
    }
  }

  &__list-title {
    @extend .heading-larger;

    color: map-get($font-color, 'light');
    margin-right: rem(80px);
    max-width: 209px;

    @include media-breakpoint-up(md) {
      margin-right: 0;
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      margin-top: calc(100% + 400px);
      transition: all 0.2s ease-out;
      margin-bottom: rem(30px);
      margin-bottom: rem(30px);
    }
  }

  &__list-text {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      opacity: 0;
      transition: all 0.2s ease-out;
      color: map-get($font-color, 'light');
      min-height: 300px;
    }
  }

  &__list-image {
    height: 100%;
    width: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: all 0.2s;
  }

  &__item {
    @extend %image-hover-red-mask;

    padding: rem(50px);
    padding-bottom: 28%;
    flex: 1;
    margin: 10px;
    overflow: hidden;
    transition: all 0.2s ease-out;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: 0;
      background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 17%;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 48%;

      &:hover {
        .capabilities__list-image {
          filter: grayscale(50%) contrast(200%);
        }

        .capabilities__list-text {
          opacity: 1;
        }
    
        .capabilities__content {
          margin-top: 0;
        }
    
        .capabilities__list-title {
          margin-top: 0;
        }

        &:before {
          opacity: 0.75;
        }

        .capabilities__list-arrow {
          opacity: 1;

          &:before {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__content {
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__list-hovered {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    bottom: 0;
    margin-top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(40px);

    @include media-breakpoint-up(lg) {
      padding: 0 rem(80px);
    }
  }

  &__list-arrow {
    border: solid 2px white;
    border-radius: 100%;
    width: rem(85px);
    height: rem(85px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
    top: auto;
    right: 20px;
    bottom: 0;
    opacity: 1;
    transition: all 0.2s ease-out;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      opacity: 0;
      bottom: 30px;
      width: rem(65px);
      height: rem(65px);
      right: 30px;
      position: absolute;
    }

    &:before {
      @include arrow;
      width: rem(30px);
      margin-top: 2px;
      transition: transform 0.2s ease-out;

      @include media-breakpoint-up(lg) {
        transform: translateX(-100%);
      }
    }

    &:hover {
      background: map-get($background-color, 'primary');
      border-color: map-get($background-color, 'primary');
    }
  }
}