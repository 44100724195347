.solutions-newsletter {
  @extend .component-padding;

  background: map-get($background-color, 'tertiary');
  background-size: cover;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--new-post-subscription {
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
    display: flex;

    &:last-child {
      margin-bottom: 0 !important;
    }

    @include media-breakpoint-up(md) {
      min-height: auto;
    }

    .col {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }
  }

  &::before {
    content: "";
    background-image: linear-gradient(to top, rgba(17, 17, 17, 0) 0%, #111111 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__title {
    @extend .heading-xxx-large;

    color: map-get($font-color, 'light');
    margin-bottom: 2rem;
  }

  &__text {
    color: map-get($font-color, 'light');

    font-size: rem(24px);
    line-height: 2;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: row;
    background: map-get($background-color, 'primary');

    @at-root .solutions-newsletter--new-post-subscription & {
      background: map-get($background-color, 'tertiary');
      width: 100%;

      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    padding: $spacing-large;
    position: relative;
    z-index: 5;

    .gform_confirmation_message {
      color: white;
    }
  }

  &__form {

    .gform_fields li {
      margin-bottom: 10px;
    }

    input {
      display: block !important;
      margin-bottom: 0;

      &[type="submit"] {
        background: black !important;

        @at-root .solutions-newsletter--new-post-subscription & {
          background: white !important;
          color: black !important;
        }
      }
    }

    label {
      display: none;
    }

  }

  &__button {
    background-color: map-get($background-color, 'secondary');
    color: map-get($font-color, 'dark') !important;

    @at-root .solutions-newsletter--new-post-subscription & {
      color: map-get($font-color, 'dark') !important;
    }

    &:hover {
      color: map-get($font-color, 'light') !important;

      @at-root .solutions-newsletter--new-post-subscription & {
        color: map-get($font-color, 'dark') !important;
      }
    }
  }


  &__sub-title {
    @extend .heading-medium-small;

    color: map-get($font-color, 'light');
    margin-bottom: 1rem;
    display: block;
  }
}