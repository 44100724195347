.contact-cards {
  z-index: 1;
  bottom: 0;
  left: 0;
  position: relative;
  overflow: hidden;
  margin: 0 0 rem(40px) 0;
  flex: 1;
  color: map-get($font-color, 'primary');
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  flex-direction: column;

  &__cards-wrapper {
    margin-left: -$spacing-small;
    margin-right: -$spacing-small;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      min-width: auto;
    }
  }

  &__card-title {
    @extend .heading-larger;

    color: map-get($font-color, 'highlight') !important;
    margin-bottom: $spacing-small;
  }

  &__card-address-row {
    display: block;
    margin-bottom: rem(24px);

    a {
      color: map-get($font-color, 'primary');
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__card-map {
    width: 100%;
    padding-bottom: 66%;
    min-height: 100%;
    position: relative;

    img {
      object-fit: cover;
      object-position: bottom;
      max-height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  &__card-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-small;
    flex-basis: 33%;

    &--map {
      flex-basis: 66%;
    }
  }

  &__card-col__inner {
    flex: 1;
  }

  &__label {
    color: map-get($font-color, 'highlight');
    font-size: rem(24px);
    font-weight: 400;
    display: block;

    @include media-breakpoint-up(sm) {
      display: inline;
    }
  }

  &__button {
    margin-top: $spacing-small;
  }
}