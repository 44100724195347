.text-columns {
  &__container {
    display: flex;
    gap: 2rem;
  }

  &__column {
    flex: 1;
  }

  &--3 {
    @extend .small-text;
  }
}
