.copy-styles, #job {
  strong {
    color: map-get($font-color, 'dark' );
    font-weight: 500;
  }

  h3 {
    @extend .heading-x-large;
  
    color: map-get($font-color, 'dark');
  }
  
  h3 {
    @extend .heading-large;
  
    color: map-get($font-color, 'dark');
  }
  
  h4 {
    @extend .heading-medium;
  
    color: map-get($font-color, 'highlight');
  }
  
  ul {
    list-style: disc;
    padding-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
    li {
      margin-bottom: 2rem;
      position: relative;
  
      &::before {
        content: "•";
        display: inline-block;
        color: map-get($font-color, 'highlight');
        margin-right: 1rem;
        left: -15px;
        position: absolute;
      }

      ul {
        margin-left: 10rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

p {
  line-height: rem(40px);
}