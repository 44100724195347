.card-layout {
  @extend .component-padding;

  margin: 2rem;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

  &--dark {
    @include media-breakpoint-up(lg) {
      background: map-get($background-color, 'tertiary');
    }
    .container {
      @include media-breakpoint-up(lg) {
        padding-right: 0;
      }

      align-items: stretch;
    }

  }

  .container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }

  &--grey {
    background: map-get($background-color, 'body-main');
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }

  &__title {
    @extend .heading-larger;

    max-width: 900px;
    margin-bottom: 2rem;

    @at-root .search & {
      max-width: 100%;
    }

    margin-right: auto;
    color: map-get($font-color, 'dark');

    span {
      color: map-get($font-color, 'highlight');
    }

    @at-root .card-layout--dark & {
      color: map-get($font-color, 'light');
    }
  }

  &__text {
    margin-top: $spacing-small;
    margin-right: auto;
  }

  &__cards {
    // margin-top: $spacing-small;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4rem 0;
    //margin-right: -25px;
    //margin-left: -25px;
//    min-width: 100%;

    @include media-breakpoint-up(md) {
      gap: 0 2rem;
    }

    &--no-wrap {
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
          width: 10px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: black;      /* color of the tracking area */
          border-radius: 90px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(white, 0.2);    /* color of the scroll thumb */
          border-radius: 90px;       /* roundness of the scroll thumb */
          border: 5px solid black;  /* creates padding around scroll thumb */
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  article {
    width: 100%;
  }
}
