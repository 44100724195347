.listing {

}

.job-listing-wrapper {

}

.jobs-listing {
  list-style: none;
  padding-left: 0;
}

.jobs-item {
  background: white;
  padding: $spacing-med;
  margin-bottom: $spacing-small;

  h3 {
    @extend .heading-small;

    padding-bottom: $spacing-small;

    a {
      color:  map-get($font-color, 'dark');
    }
  }

  .list-info {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: $spacing-small;

    li {
      background: map-get($background-color, 'primary');
      padding: 4px 10px;
      margin: 0;
      margin-right: 1rem;
      color: white;
      font-size: 14px;
      font-weight: 400;

      &.locations {
        background: none;
        color: map-get($font-color, 'primary');
      }
    }
  }

  .controls {
    margin-bottom: $spacing-med;
  }

  .btn {
    @extend .button;
    @extend .button--small;

    border: 0;
    border-radius: 0;

    &.applyurl {
      margin-left: $spacing-small;

    }

    &.jobdetailurl {
      background: none;
      color: map-get($font-color, 'dark');
      text-decoration: underline !important;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.paging-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
 
  .paging-item {
    background: white;
    color: map-get($font-color, 'dark');
    font-weight: 300;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;

    &:hover, .active-page {
      color: map-get($font-color, 'highlight');
    }
  }
}

.template-careers .careers {

  .button {
    display: inline-flex;
    color: white !important;

    .count {
      margin-left: 8px;
      font-size: smaller;

      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }

  #campaignsDiv {
    display: none;
  }

  #messages {
    bottom: 0;

    #message-list {
      padding-left: 0;
      margin-left: 0;
      color: red;
      font-weight: 500;
      //padding: 2rem;

      li {
        list-style: none;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  h2 {
    @extend .heading-large;

    margin-bottom: $spacing-small;
    color: map-get($font-color, 'dark');
  }

  .page-intro {
    background: none;
  }
  #search-results{
    table {
      width: 100%;
    }

    tr {
      margin-bottom: 2rem;
    }

    .job-externalJobNo {
      background: map-get($background-color, 'primary') !important;
      padding: 2px 10px;
      color: white;
      margin-bottom: 2rem;
      display: inline-block;
    }
  }

  #job-mail-content {
    background: black;
    padding: 4rem;
    color: white;
    margin-bottom: 3rem;

    display: none;
  }

  #job-mail {

    #job-mail-trigger {
      @extend .heading-large;
  
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      margin-bottom:  2rem;
      display: block;
    }

    &.focus {
      #job-mail-content {
        display: block;
      }
    }
  }

  #job-mail-subscribe-button {
    background: map-get($background-color, 'primary') !important;
  }

  #recent-jobs, #search-results {
    display: block;
    thead {
      display: none;
    }
    tr {
      display: block;
      background: white;
      padding: $spacing-med;
    }
    tr td {
      display: block;
      background: white;
    }
    .summary {
      margin-bottom: 30px;
      background: white;
      padding: $spacing-med;
      padding-top: 0;
    }

    .job-link {
      @extend .heading-large;
      margin-bottom: $spacing-small;
      display: block;
      color: map-get($font-color, 'dark') !important;

    }

    .close-date:before {
      content:"Close Date: ";
    }

    .location, .close-date {
      font-size: smaller;
      color: #353535;
    }

    .more-link {
      color: white !important;
    }
  }
}

.page-template-template-careers {
  .search {
    background: white;
    padding: $spacing-med 4rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .hero--full-height {
    min-height: 55vh;
    min-height: auto;
  }

  .hero--full-height .container {
    margin-bottom: 14vh!important;
    width: auto;
  }
}

#search-filters {
  display: flex !important;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  justify-content: space-around;

  h3 {
    display: none;
  }

  .filter-select {
    width: 100%;
    margin: 10px;

    @include media-breakpoint-up(md) {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    h3 {
      font-size: rem(18px);
      display: block;
      margin-bottom: 8px;
      color: map-get($font-color, 'primary');
    }
  }

  /*
  .filter-select:before {
    content: "&nbsp;";
    @extend .heading-large;
    font-weight: 700;
    color: map-get($font-color, 'dark');
    margin-bottom: 20px;
    display: block;
    opacity: 0;
  }

  .filter-select:first-child:before {
    content: "Filter";
    opacity: 1;
  }
  */

  #filter-results-submit {
    background: map-get($background-color, 'primary') !important;
    margin-top: 31px;
    margin-left: 1rem;
  }

  #search-keyword {
    &:after {
      content: 'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31"><g><g><path fill="none" stroke="#BE2228" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="3" d="M22.486 22.486A11.964 11.964 0 0 1 14 26.002C7.375 26.002 1.998 20.629 1.998 14 1.998 7.375 7.375 1.998 14 1.998c6.629 0 12.001 5.377 12.001 12.002 0 3.314-1.343 6.314-3.515 8.486L29.001 29v0z"/></g></g></svg>';
    }
  }
}

#jobs-form td {
  border-top: 0 !important;
  padding: 0 !important;
}

#jobs-form .button {
  color: white;
  padding: 0;
  border-radius: 0;
}

#jobs-form .apply-link.button, #jobs-form .back-link.button {
  border-radius: 0;
  margin-bottom: 4rem;
  margin-top: 1rem;
  height: 46px !important;
  border: none !important;
}

#jobs-form {
  .back-link.button {
    color: red !important;
    background: none !important;

    &:before {
      display: none !important;
    }

    &:after {
      display: none !important;
    }

    font-weight: 200 !important;

    &:hover {
      &:before {
        display: none !important;
      }
  
      &:after {
        display: none !important;
      }
    }
  }
}

#job-content {
  padding: 4rem;
  background: white;

  h1 {
    @extend .heading-x-large;
  }

  h1, h2, h3, h4, strong, b {
    color: map-get($font-color, 'dark');

    margin-top: 3rem;
    display: block;

  }

  .back-link.button {
    margin-top: 4rem;
    padding: 0 2rem;
    border-radius: 0;
  }
}

#social-media {
  margin-top: 4rem;

  h2 {
    display: none;
  }

  .addthis_toolbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .at-icon-wrapper,
  .at-icon-wrapper svg {
    width: 50px !important;
    height: 50px !important;
  }

  .at-icon-wrapper {
    margin: 0 2px;
  }

  .addthis_separator {
    margin: 0 1rem;
  }

  .social-media-list li:before {
    list-style-type: none !important;
    display: none !important;
  }

  .addthis_button_expanded {
    display: flex;
    align-items: center;

    .at-icon-wrapper {
      border-radius: 100%;
      margin-right: 1rem;
      height: 30px !important;
      width: 30px !important;

      svg {
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
}

.g-recaptcha, #job-mail-email {
  margin-bottom: 2rem;
}

#job-mail-subscribe p {
  margin-bottom: 2rem;
}

.copy-styles ul li:before, .page-intro ul li:before, .title-text ul li:before, #job ul li:before {
  content: "";
  display: none;
}
