@media only screen and (max-width: 1200px) {

  .header__brand {
    z-index: 99;
    min-width: 80px;
  }

  .menu {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: block;
    top: 0;
    left: 0;
    background: black;
    padding: 3rem;
    padding-top: 100px;
    opacity: 0;
    pointer-events: none;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: clip;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  .primary-menu {
    display: flex;
    align-items: center;
    gap: 6rem;

    &__back-button {
      margin-bottom: 3rem;
      margin-top: 8rem;
      display: flex;
      font-size: 2rem;

      &:hover {
        text-decoration: none;
      }

      svg {
        max-width: 14px;
        max-height: 14px;
        fill: #d6d6d6;
      }
    }

    &__item {
      font-weight: 600;
      white-space: nowrap;
      display: flex;
      height: 100%;
      align-items: center;
      list-style: none;
      margin-bottom: 1rem;

      a {
        color: #d6d6d6;
        font-size: 2.4rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {
          text-decoration: none;
        }
      }

      span {
        color: white;
        margin-bottom: 1rem;
        display: block;
        font-size: 2.6rem;
      }
    }

    &__image {
      display: none;
    }

    &__child {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 1;
      background: black;
      padding: 3rem;
      transition: transform 0.3s ease;
      transform: translateX(100%);
    }

    &__child-list-child {
      margin-bottom: 4rem;
    }

    &__link--has-child.active + .primary-menu__child {
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    }
  }
}


.primary-menu__item > a {
    @extend %link-underline;

    &:before {
      bottom: 32px !important;
     // background-color: ;
    }

    &:hover {
      color: white;
    }
}

@include media-breakpoint-up($menu-horizontal) {
  .menu {
    display: flex;
    height: 100%;
    position: static;
  }

  .primary-menu {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 6rem;

    &__back-button {
      display: none;
    }

    &__child-link.primary-menu__link--2 {
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    &__image {
      width: 30%;
      max-width: 423px;
      position: relative;
      min-height: 100%;
      margin-right: auto;
      display: flex;
      align-self: stretch;

      img {
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        position: absolute;
        transform: translateX(-30px);
        transition: transform 0.3s ease;
      }
    }

    &__child-item {
      max-width: 600px;
    }


    &__item {
      list-style: none;

      &:hover {

        .primary-menu__child {
          opacity: 1;
          pointer-events: all;
          animation: fadeIn 0.5s forwards;
          //animation: fadeOut 0.5s forwards 0.5s; /* delay of 0.5s before fading out */

        }

        .primary-menu__image img {
          transform: translateX(0);
        }

        .primary-menu__child-list {
          opacity: 1;
        }
      }
    }

    &__child {
      opacity: 0;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      display: flex;
      gap: 3rem;
      justify-content: center;
      align-items: flex-start;
      min-height: 700px;
      z-index: -1;
      background: black;

      //transition: all 0.6s ease-in 0.6s;
      //transition: all 0.3s ease;


      > ul {
        display: flex;
        gap: 6rem;
        padding: 3rem;
        justify-content: center;
        align-items: flex-start;
        list-style: none;

        a {
          color: rgba(255, 255, 255, 0.748);
          font-weight: 600;
        }
      }

      &-link span {
        font-weight: 600;
        margin-bottom: 1rem;
        display: block;
        color: white;
        font-size: 2.3rem;
      }
    }

    &__child-list {
      list-style: none;
      padding: 0;
      gap: 6rem;
      //opacity: 0;
      //transition: opacity 0.3s ease;
      flex: 1;
      justify-content: flex-start;
      margin-top: 140px;
      justify-content: center;

      &-child {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1.2rem;
      }
    }

    &__link {
      font-weight: 600;
      color: #e3e3e3;
      white-space: nowrap;
      display: flex;
      height: 100%;
      align-items: center;
      list-style: none;
      font-size: 1.8rem;
    }

    > li {
      display: flex;
      height: 100%;
      align-items: center;
    }

    > li > a {
      color: white;
      white-space: nowrap;
    }

    > li > ul {


      > li > a {
        font-weight: 600;
        color: #e3e3e3;
      }

      a {
        color: white;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  .menu {
    margin-left: auto;
    max-width: 100vw;

    &__list {
      margin-left: auto;
      display: flex;
      justify-content: center;
      gap: 3rem;
      margin-bottom: 0;
      height: 100%;
    }
  }


  .sub-navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: black;
    height: rem(80px);
    padding: 0 $spacing-med;
    margin-top: -#{$spacing-large};
  //  margin-bottom: $spacing-large;

    &__nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      margin-left: 0;
    }

    &__item {
      display: block;
      flex: 1;
      text-align: center;
      padding: 0 rem(20px);
    }

    &__link {

      display: block;
      font-size: rem(20px);
      color: map-get($font-color, 'light');
      font-weight: 500;
      white-space: nowrap;
      padding: 0 5px;

      &:hover {
        color: map-get($font-color, 'light');
      }

      &--active {
        &:before {
          transform: scaleX(1);
          transition: transform 0.2s ease;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.main .sub-navigation__link {
  @extend %link-underline;

  &:hover {
    color: white;
  }

  &--active {
    &:before {
      transform: scaleX(1);
    }
  }
}
