.text-image {
  @include wysiwyg-content;

  padding-top: 5.5rem;
  padding-bottom: 5.5rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 6.5rem;
    padding-top: $spacing-large;
  }

  min-height: 0;
  background: white;

  &__title {

    @at-root .home & {
      @extend .heading-xx-large;
      line-height: 1;
      max-width: 700px;
    }

    @extend .heading-larger;

    max-width: 900px;
    margin-right: auto;
    margin-bottom: 20px;
    color: map-get($font-color, 'dark');
  }

  &__card-title {
    color: map-get($font-color, 'dark') !important;
  }

  &__card-sub-title {
    @at-root .home & {
      color: map-get($font-color, 'highlight');
    }

    &:before {
      bottom: -5px !important;
    }
  }

  .container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: auto;
      flex-wrap: wrap;
      gap: 2rem;
      //margin-left: -#{rem(20px)};
      //margin-right: -#{rem(20px)};
    }

    @include media-breakpoint-up(xl) {
      //margin-left: -#{rem(60px)};
      //margin-right: -#{rem(60px)};
    }

    &--has-one { // remove negative marging and parent container padding
      // @include offsetContainerPadding();
      gap: 0;
    }

    &--thin {

      gap: 3rem;

      @include media-breakpoint-up(lg) {
        gap: 8rem;
      //  margin-left: -60px;
      //  margin-right: -60px;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
    bottom: 0;
    left: 0;
    position: relative;
    overflow: hidden;
    flex: 1;
    color: map-get($font-color, 'primary');
    min-width: 200px;

    //margin-bottom: rem(20px);

    @include media-breakpoint-up(lg) {
      &--stats {
        max-width: 500px;
        padding-left: 30px !important;
        padding-right: 0 !important;
      }

      &--img-width-20 {
        .text-image__card-image-container {
          flex: 0.4 !important;
        }
      }

      &--img-width-30 {
        .text-image__card-image-container {
          flex: 0.6 !important;
        }
      }

      &--img-width-40 {
        .text-image__card-image-container {
          flex: 0.8 !important;
        }
      }

      &--img-width-50 {
        .text-image__card-image-container {
          flex: 1 !important;
        }
      }


      &-image-container {
        height: 100%;
        width: 100%;

        img {

        }
      }
    }



    &--original {
      .text-image__card-image-container {
        .text-image__card-image {
          position: relative;
          width: 100%;
          height: auto;
          transform: unset !important;
        }

        &:hover {
          .text-image__card-image {
            transform: unset;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &--full-width {
        flex-direction: row;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-height: 300px;

        &.text-image__card--right {
          flex-direction: row-reverse;
        }

        .text-image__card-image-container {
          flex: 1;
          margin-bottom: 0;
          padding-bottom: 0

        }

        .text-image__card-text {
          flex: 1;
          padding: 0 $spacing-med;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: map-get($font-color, 'primary');

      .text-image__card-image {
        transform: scale(1.06);
      }

      .text-image__card-sub-title {
        &:before {
          transform: scaleX(1);
          transition: transform 0.2s ease;
        }
      }
    }

    &-image-container {
      padding-bottom: 0%;
      overflow: hidden;
      position: relative;
      margin-bottom: 2.125rem;
      aspect-ratio: 16/9;
      min-height: 210px;
      max-width: 100%;
    }

    &-image {
      height: 100%;
      width: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      z-index: 0;
      transition: all 0.2s;
      transform: scale(1);

      @at-root .text-image__card--keep-proportions &{
        height: auto !important;
        display: block;
        position: relative;
      }
    }

    &-title {
      @extend .heading-medium;

      color: map-get($font-color, 'highlight');
      margin-bottom: $spacing-med;
    }

    &-sub-title {
      @extend .heading-small;

      position: relative;
      color: map-get($font-color, 'highlight');
      margin-bottom: $spacing-med;
      display: inline-block;

      &:before {
        content: "";
        width: 50%;
        height: 2px;
        position: absolute;
        bottom: rem(-20px);
        transform: scaleX(0);
        margin-top: 3px;
        display: block;
        transform-origin: left;
        background: map-get($font-color, 'highlight');
      }
    }
  }

  &__stats {
    background: map-get($background-color, 'primary');
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    margin-top: 3rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__stat {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: solid 1px white;
      margin-top: 2rem;
      margin-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 13px;
      }
    }

    &:last-child:after {
      display: none;
    }

    &-number {
      font-size: map-get($font-size, 'heading-large');
      color: white;
      display: block;
      font-weight: 600;

      @include media-breakpoint-up(lg) {
        font-size: 5rem;
      }
    }

    &-text {
      font-size: map-get($font-size, 'heading-small');
      color: white;
      display: block;
      font-weight: 500;
      margin-bottom: auto;

      @include media-breakpoint-up(lg) {
        font-size: map-get($font-size, 'heading-medium');
      }
    }
  }
}


// Layout specific
.content-thin .text-image__card--right .text-image__card-text {
  padding-left: 0;
}

.content-thin .text-image__card--right .text-image__card-left {
  padding-right: 0;
}

.content-thin .text-image__card--right .text-image__card-left .card-image-container {
 // margin-bottom: 5rem;
}
