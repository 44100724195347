@mixin list-padding {
  padding-top: rem(60px);
  padding-bottom: rem(60px);

  @include media-breakpoint-up($menu-horizontal) {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
  }

  @include media-breakpoint-up('xl') {
    padding-top: rem(100px);
    padding-bottom: rem(100px);
  }
}

$transition-time: 0.5s;
$transition-animation-time: 1s;

.menu-active .popover-menu {
  opacity: 1;
  //transition: width $transition-time ease-out;
  @include media-breakpoint-up('lg') {
    animation-name: openMenu;
    animation-duration: $transition-time;
  }


  &, & * {
    pointer-events: all;
  }
}
/* The animation code */
@keyframes openMenu {
  from {width: 0; opacity: 1}
  to {width: 100%; opacity: 1}
}
.popover-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 10;
  font-size: map-get($font-size, 'heading-large');
  font-weight: 600;
  position: fixed;
  padding-bottom: 0;
  background-color: map-get($background-color, 'primary');
  background-image: url('../images/menu-bg.png');
  background-repeat: repeat;
  opacity: 0;
  transition: opacity $transition-time;;


  @include media-breakpoint-up($menu-horizontal) {
    margin-left: $menu-size;
    width: calc(100vw - #{$menu-size});
  }


  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #A8191E;
    background-image: url('../images/menu-bg.png');
    background-position-x: left;
    background-repeat: repeat;
    left: 0;
    top: 0;
    transition-delay: $transition-time;
    transition: width $transition-animation-time ease-out;
  }

  @at-root .menu-active & {
    &:after {
      width: 50%;
      transition-delay: $transition-time;
    }
  }

  &--child-active {
    .popover-menu__image {
      opacity: 0;
    }
  }

  &, & * {
    pointer-events: none;
  }

  &__back {
    margin: rem(20px) 0;
    display: block;
    color: map-get($font-color, 'highlight');
    display: flex;
    position: absolute;
    top: -4rem;
    left: auto;
    

    &:before {
      @include arrowThick('%23E02926');
      width: 18px;
      top: 9px;
      height: 14px;
      position: relative;
      margin-right: 10px;
      transform: rotate(180deg);
    }

    @include media-breakpoint-up($menu-horizontal) {
      display: none;
    }

    &:hover {
      font-color: map-get($font-color, 'dark');
    }
  }

  &__image {
    overflow: hidden;

    img {
      position: absolute;
      width: 120%;
      height: 100%;
      transition: transform $transition-animation-time ease-out;
      transform: translateX(0);
      transition-delay: $transition-time;
      object-fit: cover;

      @at-root .menu-active & {
        transform: translateX(10%);
      }
    }

    @include media-breakpoint-up($menu-horizontal) {
      display: flex;
      // background: url('../images/feature-image.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      right: 0;
      justify-content: flex-end;
      align-items: flex-end;
      opacity: 0;
      transition: opacity 0s;
      transition-delay: $transition-time;
      opacity: 0;

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: map-get($background-color, 'tertiary');
        background-size: cover;
        background-position: center;
        opacity: 0.5;
        pointer-events: none;
      }

      @at-root .menu-active & {
        opacity: 1;
      }
    }
  }

  &__follow {
    transform: translateY(0);
    opacity: 1;
    width: 100%;
    z-index: 99;
    left: 0;
    justify-content: center;
    text-align: center;
    bottom: 80px;

    @include media-breakpoint-up($menu-horizontal) {
      position: absolute;
      bottom: 38px;
      right: 43px;
      width: auto;
      justify-content: flex-start;
      text-align: left;
      left: auto;
      transform: translateY(200px);
      opacity: 0;
      transition: all $transition-animation-time ease-out;
      transition-delay: $transition-time;
    }

    @at-root .menu-active & {
      transform: translateY(0);
      opacity: 1;
    }

    &-heading {
      font-size: map-get($font-size, 'heading-small');
      font-weight: 500;
      color: white;
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      line-height: 1;
      margin-bottom: 0.8em;

      a:hover {
        text-decoration: none;
      }
    }
  }

  &__list {
    display: block;
    overflow: scroll;
    height: 100%;
    z-index: 2;
    position: relative;

    @include list-padding();

    padding-left: rem(64px);
    padding-right: rem(64px);

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up($menu-horizontal) {
      padding-left: rem(60px);
      padding-right: rem(60px);

      opacity: 0;
      transition: all $transition-animation-time ease-out;
      transform: translateY(200px);
      transition-delay: $transition-time;

      @at-root .menu-active & {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @include media-breakpoint-up('xl') {
      padding-left: rem(128px);
      padding-right: rem(128px);
    }
  }

  &__link {
    // @extend %link-underline;

    color: white;
    font-size: 2.8rem;
    display: block;
    align-items: center;
    position: relative;

    @include media-breakpoint-up('md') {
      font-size: map-get($font-size, 'heading-x-large' );
    }

    &--small {
      font-size: map-get($font-size, 'heading-medium' );
      display: flex;

      &:first-child {
        margin-top: 2rem;
        display: flex;
      }

      .popover-menu__link-more {
        font-size: map-get($font-size, 'heading-large' );
      }
    }

    &-more {
      transition: all 0.2s ease;
      color: map-get($font-color, 'highlight' );
      font-size: map-get($font-size, 'heading-x-large' );
      // transition: transform $transition-animation-time ease-out;
      margin-left: 10px;
      font-family: arial;
      display: inline-block;
      line-height: 1;
      pointer-events: none !important;

      @include media-breakpoint-up('md') {
        margin-left: 20px;
      }
    }

    &:hover {
      color: white;
    }

    @at-root .is-active & {
      .popover-menu__link-more {
        transform: rotate(135deg);
        transform-origin: center;
        display: inline-block;
      }
    }

    &--2 {
      margin-right: 30px;
      color: map-get($font-color, 'primary' );
      font-size: 14px;
      position: relative;
      bottom: -5px;
      max-width: 150px;

      @include media-breakpoint-up('md') {
        max-width: 200px;
        font-size: map-get($font-size, 'heading-medium' );
      }

      a:hover {
        color: map-get($font-color, 'highlight' );
      }
    }

    &--3 {
      color: map-get($font-color, 'dark' );
      font-size: 20px;


      @include media-breakpoint-up('md') {
        font-size: map-get($font-size, 'heading-large' );
      }

      a:hover {
        color: map-get($font-color, 'primary' );
      }
    }
  }

  &__angle {
    position: absolute;
    right: -160px;
    top: -20px;
    background: white;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    opacity: 0;
  }

  // Animation for White Sub Menu
  &__item {
    @include media-breakpoint-up($menu-horizontal) {
      max-width: calc(50% - 100px);
    }

    &.is-active > .popover-menu__child {
      opacity: 1;
      pointer-events: all;
      max-width: 1200px;
    }

    &.is-active .popover-menu__angle {
      transition: all 0.3s ease;
      opacity: 1;
      right: -140px;
      transition-delay: 500ms;
    }

    .button {
      max-width: 200px;
      font-size: 16px;
      margin-top: 2rem;
    }
  }

  &__child {
    list-style: none;
    position: absolute;
    height: 100%;
    top: 0;
    background: white;
    overflow: scroll;
    transition: opacity 0.5s ease-in;
    opacity: 0;
    pointer-events: none;
    max-width: 0;
    width: 100%;
    left: 0;
    padding-top: 5rem;
    z-index: 2;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up($menu-horizontal) {
      width: 50%;
      left: auto;
      right: 0;
      padding-top: 0;
    }

    .container {
      min-width: 50vw;
    }

    span {
      pointer-events: none;
    }
  }

  &__child-row {
    @include media-breakpoint-up($menu-horizontal) {
      width: 65%;
      padding-top: 0;
      .col {
        left: 65px;
        padding-left: 0;
      }
    }
  }

  &__child-list {
    padding-left: rem(0);
    padding-right: rem(0);

    @include list-padding();

    @include media-breakpoint-up($menu-horizontal) {
     // padding-left: rem(80px);
      //padding-right: rem(80px);
    }

    @include media-breakpoint-up('xl') {
      padding-left: rem(80px);
      padding-right: rem(80px);
    }
  }

  &__child-item {
    display: flex;
    flex-direction: row;
    margin-top: -0.1em;
    position: static;

    &.is-active > .popover-menu__child-list-child {
      opacity: 1;
      pointer-events: all;
      z-index: 9;
    }

    &.is-active, &:hover {
      > a {
          color: map-get($font-color, 'highlight' );
      }
    }
  }

  &__child-link span {
    pointer-events: none !important;
  }

  &__child-list-child { // 3rd level
    position: absolute;
    top: 0;
    left: 46%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    padding-left: 0;
    max-width: 35%;

    @include media-breakpoint-up(lg) {
      left: 250px;
      max-width: none;
    }

    @include media-breakpoint-up(xl) {
      left: 340px;
    }

    > li a {
      color: black;
    }
  }

  &__parent-link-title {
    color: map-get($font-color, 'highlight' );
    font-size: map-get($font-size, 'heading-medium' );
    margin-bottom: rem(40px);
    display: block;
  }

  .footer-main__social-list a {
    color: #F5F5F5;
  }
}