@font-face {
  font-family: 'arial_mt';
  src: url("../fonts/arialmtstd-webfont.woff2") format("woff2"), url("../fonts/arialmtstd-webfont.woff") format("woff"), url("../fonts/arialmtstd-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'arial_mt';
  src: url("../fonts/arialmtstd-bold-webfont.woff2") format("woff2"), url("../fonts/arialmtstd-bold-webfont.woff") format("woff"), url("../fonts/arialmtstd-bold-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'arial_mt';
  src: url("../fonts/arialmtstd-bolditalic-webfont.woff2") format("woff2"), url("../fonts/arialmtstd-bolditalic-webfont.woff") format("woff"), url("../fonts/arialmtstd-bolditalic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'arial_mt';
  src: url("../fonts/arialmtstd-light-webfont.woff2") format("woff2"), url("../fonts/arialmtstd-light-webfont.woff") format("woff"), url("../fonts/arialmtstd-light-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'arial_mt';
  src: url("../fonts/arimo-regular-webfont.woff2") format("woff2"), url("../fonts/arimo-regular-webfont.woff") format("woff"), url("../fonts/arimo-regular-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@import "common/variables";
@import 'accordion-js/dist/accordion.min.css';

/** Import everything from autoload */

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */

$hamburger-padding-x: 5px;
$hamburger-padding-y: 5px;
$hamburger-layer-width         : 28px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-layer-color         : #FFF !default;
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~hamburgers/_sass/hamburgers/base";
@import "~hamburgers/_sass/hamburgers/types/collapse";
@import "~flickity/css/flickity";
@import "~jquery-datetimepicker/jquery.datetimepicker.css";

$grid-gutter-width: 28rem;

$grid-breakpoints: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 1200px,
  xl: 1600px
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
) !default;

$theme-colors: (
  primary: #E02926
);

@import "autoload/bootstrap";
@import "common/copy-styles";
@import "common/grid";
@import "common/global";
@import "common/cta";

$plyr-color-main: map-get($background-color, primary);
@import "~plyr/dist/plyr";

/** Import theme styles */
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/hero";
@import "components/capabilities";
@import "components/news-and-insights";
@import "components/solutions-magazine";
@import "components/arrow-cta";
@import "components/text-image";
@import "components/text-video";
@import "components/images-video";
@import "components/media-insights";
@import "components/solutions-newsletter";
@import "components/v-card";
@import "components/card-standard";
@import "components/card-solutions-magazine";
@import "components/card-solutions-edition";
@import "components/card-testimonial";
@import "components/card-contact";
@import "components/icon-text";
@import "components/values-list";
@import "components/text-columns";

@import "layouts/header";
@import "layouts/solutions-header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/popover-menu";
@import "layouts/solutions-menu";
@import "layouts/tinymce";
@import "layouts/page-intro";
@import "layouts/card-layout";
@import "layouts/news-navigation";
@import "layouts/sub-navigation";
@import "layouts/profile";
@import "components/buttons";
@import "components/carousel-component";
@import "layouts/search-popover";
@import "layouts/careers";

