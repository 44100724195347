.v-card {
  padding-bottom: $spacing-large;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  &__title {
    @extend .heading-large;

    margin-bottom: $spacing-med;
    color: map-get($font-color, 'dark');
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__image {
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    width: 160px;
    height: 160px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    flex: 1;
    min-width: 160px;
    margin-bottom: $spacing-med;

    @include media-breakpoint-up(lg) {
      margin-right: $spacing-med;
      margin-left: 0;
      margin-bottom: 0;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__column {

  }

  &__name {
    @extend .heading-medium;

    margin-bottom: 1rem;
    color: map-get($font-color, 'dark');
  }

  &__position {
    @extend .heading-small;

    margin-bottom: 1rem;
    color: map-get($font-color, 'highlight');
  }

  &__bio {
    margin-top: $spacing-med;
    margin-bottom: $spacing-med;;

    @include media-breakpoint-up(lg) {
      margin-right: $spacing-large;
      margin-bottom: 0;
    }
  }

  &__button {
    @extend .button--small;

    margin-top: 1rem;
  }
}