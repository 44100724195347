.icon-text {
  @extend .component-padding;
  text-align: center;

  &__title {
    @extend .heading-xx-large;
    max-width: 100%;
    text-align: center;
  }

  &__sub-title {
    @extend .heading-medium-small;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: normal;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__list-text {
    max-width: 520px;
    margin: 0 auto;
  }

  &__list-title {
    @extend .heading-medium-small;

    color: black;
  }

  &__list-image {
    max-width: 150px;
  }

  &__item {
    flex: 1;
    text-align: center;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      flex-basis: 50%;
      max-width: 47%;
    }
  }

  &__list-content {
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
  }
}