.button {
  background: map-get($background-color, 'primary');
  min-width: rem(280px);
  color: white;
  font-size: map-get($font-size, 'default');
  transition: all 0.4s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0 rem(20px);
  text-decoration: none;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: none !important;
  z-index: 1;
  max-width: 400px;
  letter-spacing: -0.4px;
  min-width: 155px;
  min-height: 30px;
  font-weight: 600;
  min-width: 18.7rem;
  font-size: max(1.8rem, 16px);
  height: 3.4em;


  &:before { // the base color
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease;
    left: 0;
    z-index: -2;
    background: map-get($background-color, 'primary');
  }

  &:after { // the hover
    content: "";
    position: absolute;
    height: 100%;
    border-top: 300px solid transparent;
    border-bottom: 300px solid transparent;
    transition: all 0.4s ease;
    left: 0;
    z-index: -1;
    border-left: 0 solid map-get($background-color, 'tertiary');
  }

  &:hover {
    color: white;
    text-decoration: none;

    &:after {
      width: 100%;
      transition: all 0.4s ease;
      border-left: 510px solid map-get($background-color, 'tertiary');
    }
  }

  &--grey {
    color: white !important;

    &:before { // the base color
      background: map-get($background-color, 'tertiary');
    }

    &:hover {
      color: map-get($font-color, 'dark') !important;

      &:after { // the base color
        border-color: map-get($background-color, 'light') !important;
      }
    }
  }


  &--small {
    min-width: auto !important;
  }

  &--large {
    min-width: 18.7rem;
    min-height: 6rem;
  }

  &--transparent {
    background-color: transparent !important;
    border: solid 2px map-get($font-color, 'lightgrey');

    &:before { // the base color
      background: transparent;
    }
  }

  &--white {
    color: map-get($font-color, 'dark');

    &:before { // the base color
      background: map-get($background-color, 'light');
    }

    &:hover {
      color: map-get($font-color, 'light');

      &:after { // the base color
        border-color: map-get($background-color, 'grey');
      }
    }
  }

  @include media-breakpoint-up(md) {
    width: auto;

    &--small {
      height: rem(60px);
      min-width: rem(160px);
      font-size: rem(20px);
    }
  }

  &:hover {
    background: map-get($background-color, 'tertiary');
  }
}

input[type="submit"],
input[type="button"] {
  border: 0;
}
