.search-popover {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: map-get($background-color, 'tertiary');
  top: 0;
  left: 0;
  z-index: 60;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;

  @at-root .search-active & {
    opacity: 1;
    pointer-events: all;
  }

  @include media-breakpoint-up(md) {
    padding-left: $menu-size;
  }

  &__inner {
    padding: 2rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: $spacing-large;
    }
  }

  &__search-input {
    background-color: rgba(96, 96, 96, 0.4);
    color: white;
    font-size: rem(32px);
    height: rem(80px);
    width: 100%;
    border: 0;
    font-weight: 700;
    padding-left: rem(55px);
    min-height: 60px;


    @include media-breakpoint-up(md) {
      font-size: rem(64px);
      height: rem(160px);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__hint {
    margin-left: auto;
    display: block;
    text-align: right;
    margin-top: 1rem;
    font-size: rem(20px);
    margin-right: rem(35px);
  }

  form {
    position: relative;

    img {
      position: absolute;
      right: 2rem;
      top: 50%;
      margin-top: -30px;
      left: auto;
    }
  }
}

.search-active .header__menu {
  background: map-get($background-color, 'tertiary');
}
