*::selection { background: map-get($background-color, 'primary'); color: white; }

*:focus {
  outline: none !important;
}

#menu-posts-training_locations {
  &:before {
    content: "Reusable Content";
    width: 100%;
    height: 2rem;
  }
}

.bg-white {
  background: white;
  overflow: hidden; // prevent margin collapse
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

strong {
  color: map-get($font-color, 'dark' );
}

.detect-mobile {
  height: 0;
  opacity: 0;
  width: 0;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

input:focus {
  box-shadow: 0 0 1px 1px map-get($background-color, 'primary');
}

html {
  // background: map-get($background-color, 'tertiary');
  font-size: 9px;
  scroll-behavior: smooth;

  @include media-breakpoint-up(md) {
    font-size: 11px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 11px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }
}

body {
  background: map-get($background-color, 'body-main');
  color: map-get($font-color, 'primary');
  font-weight: 400;
  margin-left: 0;
  margin-top: 80px;
  line-height: 1.3;
  font-family: $font-family-default;
  font-size: $font-size-mobile-large;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up($menu-horizontal) {
    font-size: map-get($font-size, 'default');
    margin-left: 0;
    margin-top: 0;

    &.single-solutions_magazine {
      margin-left: 0;
      max-width: 100%;
    }
  }

  &.home {
    background: white;
  }
}

p {
  line-height: 1.7;
  margin-bottom: 2.6rem;
}

body#tinymce {
  background: white;
  color: #2c3338;
  font-family: inherit;
}

.wrap {
  min-height: 54vh;
}

.chev {
  content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-down fa-w-14 fa-3x"><path fill="%23E02926" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" class=""></path></svg>');
  display:block;
  width:22px;
  height:10px;
  margin:10px 5px 0 10px;
}

@mixin arrow($color: white) {
  content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x"><path fill="#{$color}" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>');
  display:block;
  position: absolute;
}

@mixin arrowThick($color: white) {
  content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-2x"><path fill="#{$color}" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path></svg>');
  display:block;
  position: absolute;
}

@mixin book($color: white) {
  content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="book-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-book-open fa-w-18 fa-3x"><path fill="#{$color}" d="M514.91 32h-.16c-24.08.12-144.75 8.83-219.56 48.09-4.05 2.12-10.33 2.12-14.38 0C205.99 40.83 85.32 32.12 61.25 32h-.16C27.4 32 0 58.47 0 91.01v296.7c0 31.41 25.41 57.28 57.85 58.9 34.77 1.76 122.03 8.26 181.89 30.37 5.27 1.95 10.64 3.02 16.25 3.02h64c5.62 0 10.99-1.08 16.26-3.02 59.87-22.11 147.12-28.61 181.92-30.37 32.41-1.62 57.82-27.48 57.82-58.89V91.01C576 58.47 548.6 32 514.91 32zM272 433c0 8.61-7.14 15.13-15.26 15.13-1.77 0-3.59-.31-5.39-.98-62.45-23.21-148.99-30.33-191.91-32.51-15.39-.77-27.44-12.6-27.44-26.93V91.01c0-14.89 13.06-27 29.09-27 19.28.1 122.46 7.38 192.12 38.29 11.26 5 18.64 15.75 18.66 27.84l.13 100.32V433zm272-45.29c0 14.33-12.05 26.16-27.45 26.93-42.92 2.18-129.46 9.3-191.91 32.51-1.8.67-3.62.98-5.39.98-8.11 0-15.26-6.52-15.26-15.13V230.46l.13-100.32c.01-12.09 7.4-22.84 18.66-27.84 69.66-30.91 172.84-38.19 192.12-38.29 16.03 0 29.09 12.11 29.09 27v296.7z" class=""></path></svg>');
  display:block;
  position: absolute;
}

@mixin search($color: white) {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31"><g><g><path fill="none" stroke="#{$color}" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="3" d="M22.486 22.486A11.964 11.964 0 0 1 14 26.002C7.375 26.002 1.998 20.629 1.998 14 1.998 7.375 7.375 1.998 14 1.998c6.629 0 12.001 5.377 12.001 12.002 0 3.314-1.343 6.314-3.515 8.486L29.001 29v0z"/></g></g></svg>');
  display:block;
  position: absolute;
}

@mixin location($color: white) {
  content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-3x"><path fill="#{$color}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path></svg>');
  display:block;
  position: absolute;
}

.directions-link {
  display:flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  color: map-get($font-color, 'highlight');
  text-decoration: underline;

  &:hover {
    color: map-get($font-color, 'dark');
  }

  &:before {
    @include location('%23BE2228');
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin-right: 10px;
    position: relative;
    display: block;
    position: relative;
  }
}


.hide-desktop {
  @extend .d-md-none;
  @extend .d-lg-none;
  @extend .d-xl-none;
}

.hide-mobile {
  @extend .d-none;
  @extend .d-md-flex;
  @extend .d-lg-flex;
  @extend .d-xl-flex;
}

.flickity-button {
  display: none;
}

.template-listing .card-layout {
  padding-top: 0;
}

.next-post {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;

  &:before {
    content: "";
    background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;

  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    position: relative;
    z-index: 9;
  }

  &__sub-title {
    @extend .heading-large;
    margin-bottom: 1rem;
  }

  &__title {
    @extend .heading-xxx-large;
    margin-bottom: $spacing-med;
  }
  &__link {

  }
}

.hidden {
  opacity: 0;
}


.accordions {
  @extend .component-padding;

  &__title {
    @extend .heading-larger;

    color: map-get($font-color, 'dark');
  }

  &__text {
    margin-top: $spacing-small;
  }

  &__accordion-container {
    margin-top: $spacing-small;
  }
}

.ac {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-color: map-get($font-color, 'primary' );

  &:first-child {
    border-top: 1px solid;
  }


  .ac-panel .ac-text {
    font-family: $font-family-default;
    font-weight: 200;
    font-size: map-get($font-size, 'default' );
    line-height: rem(40px);
    color: map-get($font-color, 'primary' );
    padding: 0 0 $spacing-med 0;
  }

  .ac-header {
    height: rem(120px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ac-trigger {
    display: flex;
    justify-content: space-between;
    font-size: rem(32px);
    padding-left: 0 !important;
    padding-right: 0;

    &:after {
      @extend .chev;

      position: static;
      height: 25px;
      margin-bottom: 0;
      margin-top: 0;
      transition: transform 0.4s ease;
      transform: rotate(0deg);
    }
  }

  &.is-active {
    >.ac-header .ac-trigger {
      color: map-get($font-color, 'dark');

      &:after {
        @extend .chev;

        height: 25px;
        margin-bottom: 0;
        margin-top: 0;
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }
}



  .jobs-listing {
    @extend .card-row;

    margin-top: 0;

    flex-direction: column !important;

    @include media-breakpoint-up(lg) {
      flex-direction: row !important;
    }

    p {
//      font-size: rem(24px);
      font-size: map-get($font-size, 'default' );
      color: map-get($font-color, 'primary');
      margin-bottom: rem(90px);

      a {
        color: map-get($font-color, 'primary');
        text-decoration: none;
      }
    }

    .jobs-item {
      @extend .card-column;

      display: flex;
      flex-direction: column;
    }

    h3 {
      @extend .heading-medium;
    }

    &__categories, &__locations {
      font-size: rem(18px);
      background: map-get($background-color, 'highlight');;
      color: white;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      white-space: wrap;
      margin: 0 10px;
      max-width: 160px;
      overflow: auto;
    }

    &__locations {
      background: transparent;
      color: map-get($font-color, 'primary');
    }

    &__header {
      margin-bottom: $spacing-small;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -10px;
      margin-right: -10px;
      display: none;
    }

    &__controls {
      margin-top: auto;
      display: flex;
      margin-left: -$spacing-med;
      margin-right: -$spacing-med;
      margin-bottom: -$spacing-med;
    }

    .applyurl, .jobdetailurl {
      width: 50% !important;
      margin: 0 !important;
      height: rem(80px) !important;
    }

    .jobdetailurl:before, .jobdetailurl:after {
      display: none;
    }
  }

  #PageUpPeopleLink {
    display: none;
  }


  .carousel {
    padding-left: 2rem;
    padding-right: 2rem;
    @include media-breakpoint-up(sm) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .card-row {
    width: auto;
    flex-direction: row;
    display: flex;

    @include media-breakpoint-up(lg) {
      //margin: -#{$card-gutter*2} -#{$card-gutter} 0 -#{$card-gutter};
    }
  }

  .card-column {
    //margin: $card-gutter;
    margin-top: $card-gutter * 2;
    margin-bottom: 0;
    min-width: auto;
    flex: 1;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .title-text {
    @extend .copy-styles;
    @extend .component-padding;
  }

  .careers-login {
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    text-decoration: underline;
    box-shadow: 0px 6px 5px #4e4e4e12;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: 999;

    @include media-breakpoint-up(lg) {
      bottom: auto;
      top: 0;
      width: 200px;
    }
  }

  .card-columns {
    display: flex;
    flex-wrap: wrap;
    background: #f5f5f5;
    column-count: 3;
    column-gap: 2rem;
    orphans: 1;
    widows: 1;

    @include media-breakpoint-up(md) {
     // margin-left: -2rem;
     // margin-right: -2rem;
    }

    .card {
      flex: 1;
    }
  }

